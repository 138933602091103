import { t } from '@delphire/fp'
import * as ACLValue from './ACLValue'

export type Type = t.TypeOf<typeof codec>

export const name = 'ACL'

export const props = {
  users: ACLValue.codecRecordFromNullable,
  groups: ACLValue.codecRecordFromNullable
}

export const codec = t.type(props, name)

export const empty: Type = { users: {}, groups: {} }

export const codecFromNullable = t.fromNullable(codec, empty)
