import { t } from '@delphire/fp'

import { ACL } from '../acl'
import { TenantEntity } from '../data'
import { Link } from '../link'
import * as AgendaEvent from './AgendaEvent'

export type Type = t.TypeOf<typeof codec>

export const name = 'Agenda'

export const props = {
  startDate: t.nullable(t.DateFromISOString),
  endDate: t.nullable(t.DateFromISOString),
  locationId: t.nullable(t.string),
  iconResourceId: t.nullable(t.string),
  acl: ACL.codecFromNullable,
  links: Link.codecRecordFromNullable,
  events: AgendaEvent.codecRecordFromNullable,
  ...TenantEntity.props
}

export const codec = t.type(props, name)
