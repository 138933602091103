import { Clipboard } from '@angular/cdk/clipboard'
import { DOCUMENT } from '@angular/common'
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Clipboard as NativeClipBoard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'

import { DynamicLinkService } from './services/dynamic-link.service'
import { LocalStorageService } from './services/local-storage-service.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, AfterViewInit {
  // @HostListener('window:unload') onWindowUnload() {
  //   console.log('windowLocation', window.location)
  //   this.localStorage.set('lastLocation', window.location.pathname)
  // }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localStorage: LocalStorageService,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard,
    private dynamicLinkService: DynamicLinkService
  ) {}

  async onSnackBar() {
    const link = await this.dynamicLinkService.generateLink()
    if (Capacitor.isNativePlatform()) {
      await NativeClipBoard.write({ string: link })
    } else {
      this.clipboard.copy(link)
    }

    this.snackbar.open('Link Copied to ClipBoard!', 'Ok', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'linkSnackBar'
    })
  }
  ngOnInit(): void {
    if (Capacitor.isNativePlatform()) {
      Keyboard.hide()
    }
  }

  ngAfterViewInit(): void {
    this.document.body.setAttribute('style', 'background:none')
    if (Capacitor.isNativePlatform()) {
      Keyboard.hide()
    }
  }
}
