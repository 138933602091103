import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { ActivatedRoute, Event as NavigationEvent, NavigationStart, Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { Layout } from '../interfaces/layout'
import { Space } from '../interfaces/space'
import { DelphireBridgeService } from '../services/delphire-bridge.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { OrganizationService } from '../services/delphire-organization.service'
import { DelphireSearchService } from '../services/delphire-search.service'
import { UserService } from '../services/delphire-user.service'
import { LocalStorageService } from '../services/local-storage-service.service'

interface SearchResult {
  created_at: { raw: string },
  description: { raw: string, snippet: string },
  entity_id: { raw: string },
  entity_type: { raw: string },
  id: { raw: string },
  layout_id: { raw: string },
  layout_name: { raw: string, snippet: string },
  name: { raw: string, snippet: string },
  tenant_id: { raw: string },
  tenant_name: { raw: string },
  updated_at: { raw: string },
  _meta: { engine: string, id: string, score: number }
}

interface SearchMetaData {
  alerts: [],
  engine: { name: string, type: string },
  page: { current: number, size: number, total_pages: number, total_results: number },
  requestId: string,
  warnings: []
}

interface LinkParams {
  id?: string,
  resource?: { id?: string, name?: string, type?: string },
  route: ActivatedRoute,
  type: string
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})

export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  verbose: boolean = environment.production ? false : true
  canSearch = true
  currentSpace = {} as Space
  currentLayout = {} as Layout
  searchTerms = ''
  searchResults: Array<SearchResult> = []
  searchMetaData = {} as SearchMetaData
  searchOpen = false
  totalResults = "Searching for"
  totalPages = 1
  currentPage = 1
  selectedPage = 1
  linkParams = {} as LinkParams

  @Output('onSearchFocus') onSearchFocus = new EventEmitter()
  @Output('onSearchBlur') onSearchBlur = new EventEmitter()

  @ViewChild('searchInput') searchInputField: ElementRef | any

  constructor(
    private localStorage: LocalStorageService,
    private bridge: DelphireBridgeService,
    private router: Router,
    private searchService: DelphireSearchService,
    private linkService: DelphireLinkService,
    private organizations: OrganizationService,
    private users: UserService,
    private route: ActivatedRoute
    ) { }


  routerEvent = this.router.events.subscribe((event: NavigationEvent) => {
    if(event instanceof NavigationStart) {
      this.canSearch = event.url == '/spaces' || event.url == '/' ? false : true
    }
  })

  doSearch(page?: number): void {
    if(this.searchTerms) {

      this.searchService.setResultsVisibility(true)

      this.localStorage.getItem('currentSpace').subscribe((space: Space) => {
        this.currentSpace = space
      })

      this.localStorage.getItem('currentLayout').subscribe((layout: Layout) => {
        this.currentLayout = layout
      })

      this.currentPage = page ? page : this.currentPage

      const searchParams = {
        query: "" + this.searchTerms,
        page: {
          size: 10,
          current: page ? page : 1
        },
        filters: {
          tenant_id: [this.currentSpace.id]
        }
      }

      if(this.verbose) { console.log('%cSEARCHING FOR:', 'color: yellow', this.searchTerms) }

      this.searchService.postRequest(searchParams).then((results) => {
        results.subscribe((results) => {
          this.searchMetaData = results.body.meta
          this.searchResults = results.body.results
          if(this.verbose) { console.log('%cSEARCH RESULTS:', 'color: lime', this.searchResults) }
          if(this.verbose) { console.log('%cSEARCH METADATA:', 'color: lime', this.searchMetaData) }
          this.totalResults = String(this.searchMetaData.page.total_results)
          this.totalPages = this.searchMetaData.page.total_pages
          this.selectedPage = this.currentPage = this.searchMetaData.page.current
        })
      }).catch((error) => {
        console.error('SEARCH ERROR:', error)
      })

      this.searchOpen = true
    }
  }

  viewItem(item: SearchResult): void {
    if(this.verbose) { console.log('%c[ VIEW SEARCH ITEM ]', 'color: aqua', item) }
    console.log('%c[ VIEW SEARCH ITEM ]', 'color: aqua', item)
    const resourceTypes = ['audio', 'document', 'video', 'package', 'resource', 'externallink']
    const itemType = item.entity_type.raw.toLowerCase()

    this.linkParams.route = this.route
    this.linkParams.type = itemType

    if(resourceTypes.includes(itemType)) {
      this.linkParams.resource = {}
      this.linkParams.resource.id = item.entity_id.raw
      this.linkParams.resource.name = item.name.raw
      this.linkParams.resource.type = itemType
    } else {
      this.linkParams.id = item.entity_id.raw
    }

    if(this.verbose) { console.log('%c[ this.linkParams ]', 'color: yellow', this.linkParams) }

    switch (itemType) {
      case "layout":
      case "slideshow":
        this.router.navigate(['/spaces/' + DelphireUtilities.normalizeName(this.currentSpace.name) + '/layouts/' + item.entity_id.raw])
        break

      default:
        this.linkService.handleLink(this.linkParams)
        break
      }

      this.clearSearch()

  }

  onFocusEvent(): void {
    this.searchService.changeStatus("focused")
  }

  onBlurEvent(): void {
    this.searchService.changeStatus("blurred")
  }

  pageSelectionChange(obj: {value: number}): void {
    this.doSearch(obj.value)
  }

  getTypeIcon(type: string): string {
    // console.log("TYPE OF ICON = ", type)
    switch (type) {
      case "Audio"         : return "audiotrack"        ; break
      case "Document"      : return "picture_as_pdf"    ; break
      case "Video"         : return "ondemand_video"    ; break
      case "Module"        : return "tv"                ; break
      case "Package"       : return "snippet_folder"    ; break
      case "Quiz"          : return "psychology"        ; break
      case "Fluency"       : return "fact_check"        ; break
      case "Course"        : return "library_add_check" ; break
      case "Roadmap"       : return "today"             ; break
      case "Resource"      : return "file_copy"         ; break
      case "External Link" : return "open_in_new"       ; break
      case "ExternalLink"  : return "open_in_new"       ; break
      case "Slideshow"     : return "burst_mode"        ; break
      case "Library"       : return "vertical_split"    ; break
      case "Layout"        : return "dashboard"         ; break
      case "Agenda"        : return "calendar_today"    ; break
      case "Verbalizer"    : return "voice_chat"        ; break
      default              : return ""                  ; break
    }
  }

  clearSearch(): void {
    this.searchTerms = ''
    this.searchOpen = false
    this.searchService.setResultsVisibility(false)
    this.searchService.changeStatus("blurred")
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.searchService.forcedFocus.subscribe((focus => {
      if(this.verbose) { console.log('%c[ --------- DO FORCE FOCUS --------- ]', 'color: deeppink', focus) }

      this.searchInputField.nativeElement.focus()
      console.log('%c[ orgs ]', 'color: aqua', this.organizations)
      console.log('%c[ users ]', 'color: aqua', this.users)
    }))
  }

  ngOnDestroy() {
    this.routerEvent.unsubscribe()
  }

}
