import { t } from '@delphire/fp'
import * as Named from './Named'
import * as Timestamps from './Timestamps'

export type Type = t.TypeOfProps<typeof props>

export const props = {
  ...Named.propsWithDescription,
  ...Timestamps.propsFromISOString,
  tenantId: t.nullable(t.string)
}
