import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'WidgetStyle'

export const props = {
  backgroundColor: t.nullable(t.string),
  backgroundResourceId: t.nullable(t.string),
  descriptionColor: t.nullable(t.string),
  textColor: t.nullable(t.string),
  iconColor: t.nullable(t.string),
  opacity: t.withFallback(t.union([t.number, t.NumberFromString]), 1)
}

export const codec = t.strict(props, name)

export const empty: Type = {
  backgroundColor: null,
  backgroundResourceId: null,
  descriptionColor: null,
  textColor: null,
  iconColor: null,
  opacity: 1
}

export const codecFromNullable = t.fromNullable(codec, empty)
