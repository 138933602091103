import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'WidgetPosition'

export const props = {
  x: t.number,
  y: t.number
}

export const codec = t.strict(props, name)
