import { t } from '@delphire/fp'
import * as LinkParameters from './LinkParameters'

export type Type = t.TypeOf<typeof codec>

export const name = 'Link'

export const props = {
  type: t.string,
  parameters: LinkParameters.codec
}

export const codec = t.strict(props, name)

export const codecRecord = t.record(t.string, codec)

export const codecRecordFromNullable = t.fromNullable(codecRecord, {})
