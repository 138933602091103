import { t } from '@delphire/fp'
import { Timestamps } from '../data'
import { Link } from '../link'
import * as QuizAnswer from './QuizAnswer'

export type Type = t.TypeOf<typeof codec>

export const name = 'QuizQuestion'

export const props = {
  ...Timestamps.propsFromISOString,
  text: t.nullable(t.string),
  link: t.nullable(Link.codec),
  order: t.number,
  answers: QuizAnswer.codecArrayFromNullable,
  correct: t.fromNullable(t.array(t.number), []),
  remediation: t.nullable(t.string),
  correctScore: t.nullable(t.number),
  questionCount: t.nullable(t.number),
  incorrectScore: t.nullable(t.number)
}

export const codec = t.strict(props, name)

export const codecRecord = t.record(t.string, codec)

export const codecRecordFromNullable = t.fromNullable(codecRecord, {})
