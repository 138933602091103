<div *ngIf="canSearch" class="search-container nav-bar-search" fxFlex="100">

  <mat-form-field class="search-field" appearance="fill">
    <input matInput #searchInput type="text" placeholder="What are you looking for?" (focus)="onFocusEvent()" (blur)="onBlurEvent()" [(ngModel)]="searchTerms" (keyup.Enter)="doSearch()">
    <button class="clear" *ngIf="searchTerms" matSuffix mat-icon-button [disableRipple]="true" aria-label="Clear" (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <button class="spyglass" *ngIf="!searchTerms" matSuffix mat-icon-button [disableRipple]="true" aria-label="Search" (click)="doSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

</div>

<div id="search-results" *ngIf="searchOpen" fxLayout="column">
  <div fxLayout="row">
    <h2 fxFlex>Search Results: {{ searchTerms }}</h2>
    <div fxLayoutAlign="center center">
      <button mat-icon-button aria-label="Close Search" (click)="clearSearch()">
        <mat-icon matSuffix>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="total" *ngIf="searchResults">{{ totalResults }} results</div>
  <div class="result" *ngFor="let item of searchResults" fxLayout="row">
    <div class="icon">
      <i class="material-icons">{{ getTypeIcon(item.entity_type.raw) }}</i>
    </div>
    <div fxLayout="column">
      <div class="name" (click)="viewItem(item)">{{ item.name.raw }}</div>
      <div class="info" fxLayout="row">
        <div class="type">
          <p>Type: <span class="bold">{{ item.entity_type.raw }}</span></p>
        </div>
        <div class="layout" *ngIf="item.layout_name">
          <p>Layout: <span class="bold">{{ item.layout_name.raw }}</span></p>
        </div>
      </div>
      <div class="description" *ngIf="item.description">
        <p>{{ item.description.raw || "No Desciption" }}</p>
      </div>
    </div>
  </div>
  <div class="pagination" *ngIf="searchResults && totalPages > 1" fxLayout="row" fxLayoutAlign="center center">
    <div class="previous">
      <button mat-icon-button aria-label="Previous" (click)="doSearch(currentPage -1)" [disabled]="currentPage <= 1">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div class="page" fxLayoutAlign="center center">
      <mat-form-field>
        <mat-select [(value)]="selectedPage" (selectionChange)="pageSelectionChange($event)">
          <mat-optgroup label="Page">
            <mat-option *ngFor="let x of [].constructor(totalPages); index as i" [value]="i + 1">
              {{ i + 1 }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <span>of {{ totalPages }}</span>
    </div>
    <div class="next">
      <button mat-icon-button aria-label="Next" (click)="doSearch(currentPage +1)" [disabled]="currentPage >= totalPages">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</div>
