<div class="layout-view">
  <h2>Select A Layout</h2>
  <mat-grid-list
    gutterSize="12"
    [cols]="gridCols"
    rowHeight="1:1"
    *ngIf="layouts.length > 0"
  >
    <mat-grid-tile
      (click)="selectLayout(layout)"
      *ngFor="let layout of layouts | orderBy: 'name':true"
    >
      <div class="layout">
        {{ layout.name }}
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
