import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { getApp } from '@angular/fire/app'
import { collection, getDocs, getFirestore, query, where } from '@angular/fire/firestore'
import { ActivatedRoute } from '@angular/router'
import { HTTP } from '@awesome-cordova-plugins/http/ngx'
import { Capacitor } from '@capacitor/core'
import { environment } from 'src/environments/environment'
import { Resource } from '../interfaces/resource'
import { Widget } from '../interfaces/widget'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'


interface RecommendationItem {
  [key: string]: any
}

interface ResourceParams {
  [key: string]: any
}

interface Nudge {
  match_score?: number
  message?: string
  title?: string
}

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.sass']
})
export class RecommendationsComponent implements OnInit {
  @Input()
  widget: Widget | undefined
  verbose: boolean = environment.production ? false : true
  recommendations: RecommendationItem[] = []
  nudges: Nudge = {}
  today = new Date()

  constructor(
    private http: HttpClient,

    private nativeHTTP: HTTP,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService
  ) {}

  ngOnInit(): void {
    const { user } = this.localStorage.get('delphireUser')

    this.recommendations = [
      {
        name: 'NAME 1',
        description: 'DESCRIPTION 1'
      },
      {
        name: 'NAME 2',
        description: 'DESCRIPTION 2'
      }
    ]

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    try {
      // let _url = 'https://syneos.discourseanalytics.net/reports/data/custom?QueryName=syneos_user_idos&format=json&project=profiles&Download_Key=%7B39CC58AE-E0D6-4318-872E-27DA62C4CE5D%7D'
      // let _userId = '-M8QMCFE37ZY8tjs6glG' // jon
      let _url = self.widget?.parameters?.queryBaseUrl
      let _userId = user.id
      if (Capacitor.isNativePlatform()) {
        this.nativeHTTP
          .get(_url + '&client_user_id=' + _userId, null, null)
          .then(function (response: any) {
            console.log(response)
            const data = JSON.parse(response.data)

            self.nudges = data.arrRecords[0].nudges[0]

            let _recommendations = data.arrRecords[0].recommendations
            let _resourceIdArray: string[] = []

            _recommendations.forEach((element: any) => {
              _resourceIdArray.push(element.product_id)
            })
            console.log('_resourceIdArray', _resourceIdArray)

            const app = getApp()
            const firestore = getFirestore(app)
            const resourcesRef = collection(firestore, 'resources')
            const q = query(resourcesRef, where('id', 'in', _resourceIdArray))
            getDocs(q).then((doc) => {
              if (self.verbose) {
                console.log('%c[ doc.empty ]', 'color: aqua', doc.empty)
              }
              if (!doc.empty) {
                self.recommendations = []
                doc.docs.forEach((element: any) => {
                  self.recommendations.push(element.data())
                })
                if (self.verbose) {
                  console.log(
                    '%c[ self.recommendations ]',
                    'background: red',
                    self.recommendations
                  )
                }
              }
            })
          })
      } else {
        this.http
          .get<any>(_url + '&client_user_id=' + _userId)
          .subscribe(function (response: any) {
            console.log(response)
            const data = response

            self.nudges = data.arrRecords[0].nudges[0]

            let _recommendations = data.arrRecords[0].recommendations
            let _resourceIdArray: string[] = []

            _recommendations.forEach((element: any) => {
              _resourceIdArray.push(element.product_id)
            })
            console.log('_resourceIdArray', _resourceIdArray)

            const app = getApp()
            const firestore = getFirestore(app)
            const resourcesRef = collection(firestore, 'resources')
            const q = query(resourcesRef, where('id', 'in', _resourceIdArray))
            getDocs(q).then((doc) => {
              if (self.verbose) {
                console.log('%c[ doc.empty ]', 'color: aqua', doc.empty)
              }
              if (!doc.empty) {
                self.recommendations = []
                doc.docs.forEach((element: any) => {
                  self.recommendations.push(element.data())
                })
                if (self.verbose) {
                  console.log(
                    '%c[ self.recommendations ]',
                    'background: red',
                    self.recommendations
                  )
                }
              }
            })
          })
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  handleLink(resource: any): void {
    if (this.verbose) {
      console.log('%c[ handleLink ]', 'background: red', resource)
    }
    let linkParams = {
      route: this.route,
      type: resource.type,
      resource: {} as Resource
    }

    const resourceTypes = [
      'audio',
      'document',
      'video',
      'package',
      'resource',
      'image'
    ]
    const itemType = resource.type.toLowerCase()

    if (resourceTypes.includes(itemType)) {
      linkParams.resource.id = resource.id
      linkParams.resource.name = resource.name
      linkParams.resource.type = resource.type
    }

    //! NEED TO MAKE HANDLELINK WORK FOR PACKAGES ONLY PROBABLY
    //! BUT GETTING ERROR ON URL
    //! Cannot GET /tenants/-Linmghl3lHAxP3flsNj/resources/2637fc8c-d240-46bd-a7ec-719aa34f56da
    // else {
    //   linkParams.id = resource.id
    // }

    if (this.verbose) {
      console.log('%c[ linkParams ]', 'background: lime', linkParams)
    }

    this.linkService.handleLink(linkParams)
  }

  // $scope.handleOpenLink = (resourceId) ->
  //   tenantId = $scope.currentTenant.id
  //   if true # isInCurrentBusinessUnit($scope.currentTenant)
  //     $resource = ResourceService.getFirebaseObject resourceId
  //     # $resource = ResourceService.getFirebaseObject '06e21a92-77c0-49cd-99ca-859b94e0702a'
  //     $resource.$loaded () ->
  //       console.log '----handleOpenLink---', $resource
  //       if !$resource.id
  //         showAlert('Resource doesn\'t exist in the current tenant!')
  //         return

  //       resourcePromise = LinkService.handleResource($resource)
  //       if $resource.type is 'document'
  //         console.log 'document'
  //         $resource.documentLastTime = learning.documentLastTime
  //       else if $resource.type is 'video'
  //         console.log 'video'
  //         $resource.mediaLastTime = learning.mediaLastTime
  //       else
  //         args =
  //           type: 'Resource'
  //           parameters:
  //             resourceId: learning.resourceId
  //             layoutId: $rootScope.layouts[0].id
  //         resourcePromise = LinkService.handleLink(args)

  //       resourcePromise.then () ->
  //         console.log 'resource opened'
  //       .catch (error) ->
  //         $scope.showAlert(error)
}
