import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { fromEvent } from 'rxjs'
import { OrientationType } from '../types/orientation'

@Directive({
  selector: '[appShowMore]'
})
export class ShowMoreDirective {
  @Input()
  parentElem!: HTMLDivElement

  @Input()
  direction: OrientationType = 'right'

  windowWidth: number = window.innerWidth

  @HostListener('click') onClick() {
    if (this.direction === 'right') {
      this.parentElem.scrollLeft += 1068
    } else {
      this.parentElem.scrollLeft -= 1068
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    console.log('scrollEvent', event.target)
  }
  @HostListener('window:resize', ['$event']) onWindowResize(event: any) {
    if (this.direction === 'right') {
      if (event.target.innerWidth < this.parentElem.scrollWidth) {
        this.elementRef.nativeElement.hidden = false
      } else {
        this.elementRef.nativeElement.hidden = true
      }
    }
  }

  constructor(private elementRef: ElementRef) {}
  ngAfterViewInit() {
    if (this.parentElem.scrollWidth < this.windowWidth) {
      this.elementRef.nativeElement.hidden = true
    }
    if (this.direction === 'left') {
      this.elementRef.nativeElement.hidden = true
    }
    fromEvent(this.parentElem, 'scroll').subscribe((evt) => {
      const ele = evt.target as HTMLDivElement
      if (this.direction === 'left' && ele.scrollLeft > 0) {
        this.elementRef.nativeElement.hidden = false
      } else if (this.direction === 'left') {
        this.elementRef.nativeElement.hidden = true
      }
      if (
        this.direction === 'right' &&
        this.parentElem.scrollWidth - ele.scrollLeft <=
          this.parentElem.offsetWidth
      ) {
        this.elementRef.nativeElement.hidden = true
      } else if (this.direction === 'right') {
        this.elementRef.nativeElement.hidden = false
      }
    })
  }
}
