import { t } from '@delphire/fp'
import { TenantEntity } from '../data'
import * as LibraryItem from './LibraryItem'

export type Type = t.TypeOf<typeof codec>

export const name = 'Library'

export const props = {
  ...TenantEntity.props,
  items: LibraryItem.codecRecordFromNullable,
  iconResourceId: t.nullable(t.string)
}

export const codec = t.strict(props, name)
