import { environment } from 'src/environments/environment'
import { FirebaseService } from './firebase.service'
import { Injectable } from '@angular/core'

interface Group {
  id: string,
  name: string
}

@Injectable({
  providedIn: 'root'
})

export class GroupService {

  constructor(
    private fb: FirebaseService
  ) {
    this.init()
  }

  verbose: boolean = false
  databaseRootUrl: string = `https://${environment.firebaseConfig.iamId}.firebaseio.com`
  userGroupData: { [key: string]: any } = {}
  groupUserData: { [key: string]: any } = {}
  all: Array<Group> = []
  lookup: { [key: string]: Group } = {}
  lookupGroup: { [key: string]: Array<string> } = {}

  init = async (): Promise<void> => {

    const dataPromises = Promise.all<Promise<object>>([
      this.getUserGroupData(),
      this.getGroupUserData()
    ])

    try {
      const value = await dataPromises;
      this.userGroupData = value[0]
      this.groupUserData = value[1]
    } catch (error) {
      if (this.verbose) { console.error('[ OrganizationService - getOrgData() - PROMISE REJECTED ]', error); }
    }
  }

  // ------------------------------------------------------------------------

  getGroupUserData = (): Promise<object> => {

    return new Promise<object>((resolve, reject) => {
      this.fb
        .db(this.databaseRootUrl)
        .object(environment.firebasePaths.state + 'groupUserAssociation/groupUsers')
        .subscribe((groupUserAssociation) => {
          let arr = []
          for (const [groupId, users] of Object.entries(groupUserAssociation)) {
            // if(this.verbose) { console.log('[ group ]', groupId, users) }
            for(const key of Object.keys(users as Object)) {
              let userObject = (users as Object)[key as keyof Object]
              if(!this.lookupGroup[groupId as keyof Object]) { this.lookupGroup[groupId as keyof Object] = [] }
              this.lookupGroup[groupId as keyof Object].push(key)
            }
          }
          // console.log('this.lookupGroup', this.lookupGroup)
          resolve(groupUserAssociation)
        });
    })
  }

  // ------------------------------------------------------------------------

  getUserGroupData = (): Promise<object> => {

    return new Promise<object>((resolve, reject) => {
      this.fb
        .db(this.databaseRootUrl)
        .object(environment.firebasePaths.state + 'groupUserAssociation/userGroups')
        .subscribe((groupUserAssociation) => {
          resolve(groupUserAssociation)
        })
    })
  }

}
