import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { LocalStorageService } from './local-storage-service.service'

@Injectable({
  providedIn: 'root',
})

export class DelphireSearchService {

  async postRequest(
    params: object
  ): Promise<Observable<HttpResponse<any>>> {

    const { search } = this.localStorage.get('delphireUser')
    const url = search.baseUrl + 'engines/' + search.engineName + '/search'
    const headers = {
      Authorization: 'Bearer ' + search.searchKey,
      'Content-Type': 'application/json'
    }

    // console.log('%c[ SEARCH  SERVICE ]', 'color: aqua', { params, search, url, headers })

    return this.http.post<any>(url, params, {
      headers: headers,
      observe: 'response',
      responseType: 'json'
    })
  }

  public subject = new Subject<any>()

  public editStatusDetails: string = 'blurred'
  private statusSource = new BehaviorSubject(this.editStatusDetails)

  currentStatus = this.statusSource.asObservable()

  changeStatus(status: string) {
    this.statusSource.next(status)
  }

  public editFocusDetails: boolean = false
  private focusSource = new BehaviorSubject(this.editFocusDetails)

  forcedFocus = this.focusSource.asObservable()

  forceFocus(focus: boolean) {
    this.focusSource.next(focus)
  }

  public editResultsDetails: boolean = false
  private resultsSource = new BehaviorSubject(this.editResultsDetails)

  resultsVisible = this.resultsSource.asObservable()

  setResultsVisibility(visible: boolean) {
    this.resultsSource.next(visible)
  }

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}
}
