import { t } from '@delphire/fp'
import { TenantEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'ExternalLink'

export const codec = t.strict(
  {
    ...TenantEntity.props,
    url: t.string
  },
  name
)
