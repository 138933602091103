import { t } from '@delphire/fp'

export interface Type extends t.TypeOf<typeof codec> {}

export const name = 'LandingPageSectionVisibility'

export const codec = t.strict(
  {
    type: t.literal('acl'),
    default: t.keyof({ allow: null, deny: null }),
    users: t.record(t.string, t.boolean),
    groups: t.record(t.string, t.boolean)
  },
  name
)

export const empty: Type = {
  type: 'acl',
  default: 'allow',
  users: {},
  groups: {}
}

export const codecFromNullable = t.fromNullable(codec, empty)
