import { t } from '@delphire/fp'
import * as LandingPageUpdateParams from './LandingPageUpdateParams'

export interface Type extends t.TypeOf<typeof codec> {}

export const name = 'LandingPageUpdateResponse'

export const props = {
  parameters: LandingPageUpdateParams.codec,
  success: t.literal(true)
}

export const codec = t.strict(props, name)
