import { t as Properties } from '@delphire/fp'
import * as Section from './LandingPageSection'

export interface Type extends Properties.TypeOf<typeof codec> {}

export const name = 'LandingPageProperties'

export const props = {
  sections: Section.codecArrayFromNullable
}

export const codec = Properties.strict(props, name)

export const empty: Type = { sections: [] }

export const codecFromNullable = Properties.fromNullable(codec, empty)
