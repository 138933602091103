import { t } from '@delphire/fp'
import { ACL } from '../acl'
import { TenantEntity } from '../data'
import { Link } from '../link'

export type Type = t.TypeOf<typeof codec>

export const name = 'AgendaEvent'

export const props = {
  ...TenantEntity.props,
  locationId: t.nullable(t.string),
  iconResourceId: t.nullable(t.string),
  startDate: t.nullable(t.DateFromISOString),
  endDate: t.nullable(t.DateFromISOString),
  links: Link.codecRecordFromNullable,
  acl: ACL.codecFromNullable
}

export const codec = t.type(props, name)

export const codecRecord = t.record(t.string, codec)

export const codecRecordFromNullable = t.fromNullable(codecRecord, {})
