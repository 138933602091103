import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { Observable } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'

import { environment } from '../../environments/environment'
import { LocalStorageService } from './local-storage-service.service'

@Injectable({
  providedIn: 'root'
})
export class DelphireApiService {
  authToken: string | null = null

  public getApiToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth.getAccessTokenSilently().subscribe((token) => {
        this.authToken = token
        resolve()
      })
    })
  }
  async postScormRequest(
    method: string,
    params: object
  ): Promise<Observable<HttpResponse<any>>> {
    await this.getApiToken()
    const headers = {
      'Authorization': 'Bearer ' + this.authToken,
      'Content-Type': 'application/json'
    }

    const requestUrl = environment.scormEngine + '/api'
    const data = {
      method: method,
      params: params,
      jsonrpc: '2.0',
      id: uuidv4()
    }
    return this.http.post<any>(requestUrl, data, {
      headers: headers,
      observe: 'response',
      responseType: 'json'
    })
  }
  async postRequest(
    method: string,
    params: object
  ): Promise<Observable<HttpResponse<any>>> {
    await this.getApiToken()
    const headers = {
      'Authorization': 'Bearer ' + this.authToken,
      'Content-Type': 'application/json'
    }
    const { apiUrl } = this.localStorage.get('currentSpace')
    const requestUrl = apiUrl + method
    const data = {
      params: params
    }
    return this.http.post<any>(requestUrl, data, {
      headers: headers,
      observe: 'response',
      responseType: 'json'
    })
  }
  async getRequest(
    method: string,
    params: HttpParams
  ): Promise<Observable<HttpResponse<any>>> {
    await this.getApiToken()
    const headers = {
      'Authorization': 'Bearer ' + this.authToken,
      'Content-Type': 'application/json'
    }
    const { apiUrl } = this.localStorage.get('currentSpace')
    const requestUrl = apiUrl + method
    return this.http.get<any>(requestUrl, { params: params })
  }
  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}
}
