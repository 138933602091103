import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import * as _ from 'lodash'
import { environment } from '../../environments/environment'
import { ExternalLink } from '../interfaces/external-link'
import { Slide } from '../interfaces/slide'
import { Space } from '../interfaces/space'
import { Widget } from '../interfaces/widget'
import { DelphireApiService } from '../services/delphire-api.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.sass']
})
export class CarouselComponent implements OnInit {
  @Input()
  widget: Widget | undefined

  slides: Slide[] = []

  async openItem(slide: Slide) {
    if(slide.link) {
      console.log("##########WHAT'S YOUR TYPE: ",slide.link.type)
      switch (slide.link.type.toLocaleLowerCase()) {
        case 'resource':
          if (slide.link.parameters.resourceId) {
            const resourceId = slide.link.parameters.resourceId
            if (resourceId) {
              const res = doc(getFirestore(), 'resources', resourceId)
              const theResource = await getDoc(res)
              if (theResource.exists()) {
                const resourceObj: any = theResource.data() as any
                this.linkService.handleLink({
                  type: resourceObj.type.toLocaleLowerCase(),
                  id: slide.link.parameters.resourceId,
                  resource: resourceObj,
                  route: this.route
                })
              }
            }
          }
          break
        case 'externallink':
        case 'external link':
          if (slide.link.parameters.externalLinkId) {
            const extLinkId = slide.link.parameters.externalLinkId
            if (extLinkId) {
              const ext = doc(getFirestore(), 'externalLinks', extLinkId)
              const extLink = await getDoc(ext)
              if (extLink.exists()) {
                const extObj: ExternalLink = extLink.data() as ExternalLink
                const {url} = extObj
                this.linkService.handleLink({
                  type: 'externallink',
                  id: slide.link.parameters.externalLinkId,
                  resource: extObj,
                  route: this.route
                })
              }
            }
          }
          break
        case 'library':
          this.linkService.handleLink({
            type: slide.link.type.toLocaleLowerCase(),
            id: slide.link.parameters.libraryId,
            route: this.route.parent
          })
          break
        case 'quiz':
          this.linkService.handleLink({
            type: slide.link.type.toLocaleLowerCase(),
            id: slide.link.parameters.quizId,
            route: this.route.parent
          })
          break
        case 'agenda':
          this.linkService.handleLink({
            type: slide.link.type.toLocaleLowerCase(),
            id: slide.link.parameters.agendaId,
            route: this.route.parent
          })
          break
        default:
          const type = slide.link.type.toLocaleLowerCase()
          const parameters = slide.link.parameters as any
          this.linkService.handleLink({
            type: slide.link.type.toLocaleLowerCase(),
            id: parameters[type + 'Id'],
            route: this.route.parent
          })
          break
      }
    }
    console.log('slide', slide)
    
  }
  constructor(
    private fbs: FirebaseService,
    private delphireAPI: DelphireApiService,
    private localStorage: LocalStorageService,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.localStorage.getItem('currentSpace').subscribe((space: Space) => {
      if (space) {
        const { databaseUrl } = space
        const slides: any[] = []
        const id = this.widget?.parameters?.slideshowId
        this.fbs
          .db(databaseUrl)
          .object(environment.firebasePaths.space + 'slideshows/' + id)
          .subscribe((slideshow: any) => {
            const allSlides: any[] = Object.values(slideshow.slides)
            allSlides.forEach((value) => {
              const slide: Slide = value as Slide
              this.delphireAPI
                .postRequest('resources/getDownloadUrl', {
                  id: slide.resourceId
                })
                .then((response) => {
                  response.subscribe((response) => {
                    slide.url = response.body.result.url
                    slides.push(slide)
                    if (allSlides.length === slides.length) {
                      this.slides = _.orderBy(slides, 'order', 'asc')
                    }
                  })
                })
            })
          })
      }
    })
  }
}
