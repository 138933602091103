import { t } from '@delphire/fp'
import { IAMEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Tenant'

export const props = {
  ...IAMEntity.props,
  databaseUrl: t.string,
  apiUrl: t.string
}

export const codec = t.strict(props, name)
