import { t } from '@delphire/fp'
import { IAMEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Company'

export const props = IAMEntity.props

export const codec = t.type(props, name)
