import { t } from '@delphire/fp'

type Type = t.TypeOf<typeof codec>

const name = 'ResourceUpload'

const props = {
  bucket: t.string,
  key: t.string,
  filename: t.string,
  size: t.number
}

const codec = t.strict(props, name)

export { Type, name, codec }
