import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { DelphireBridgeService } from '../services/delphire-bridge.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { UserPreferencesService } from '../services/user-preference.service'

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.sass']
})
export class ModuleComponent implements OnInit, AfterViewInit {
  @ViewChild('module') module!: ElementRef

  moduleUrl!: string
  guid!: string
  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private bridgeService: DelphireBridgeService,

    private userPreferences: UserPreferencesService
  ) {}

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.guid = String(routeParams.get('moduleId'))
    this.localStorage.set('moduleId', this.guid)

    this.moduleUrl = this.localStorage.get('currentModulePath')
  }

  ngAfterViewInit(): void {
    this.bridgeService.default.bridgeWindow =
      this.module.nativeElement.contentWindow.window

    this.userPreferences.showDisclaimerModal()
  }
}
