import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'ACLValue'

export const codec = t.fromNullable(t.boolean, false)

export const codecRecord = t.record(t.string, codec)

export const codecRecordFromNullable = t.fromNullable(codecRecord, {})
