import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'QuizAnswerObject'

export const props = {
  answer: t.string,
  answerStatus: t.boolean
}

export const codec = t.strict(props, name)
