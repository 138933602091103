// src/app/auth.guard.ts
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { Observable } from 'rxjs'
import { delay, mergeMap, tap } from 'rxjs/operators'

import { LocalStorageService } from './services/local-storage-service.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe(
      delay(500),
      tap((loggedIn) => {
        if (!loggedIn) {
          if (Capacitor.isNativePlatform()) {
            this.auth
              .buildAuthorizeUrl()
              .pipe(
                mergeMap((url) => Browser.open({ url, windowName: '_self' }))
              )
              .subscribe()
          } else {
            this.auth.loginWithRedirect()
          }
        }
      })
    )
  }
}
