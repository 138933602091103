<div class="quiz fadeview">

  <ng-container *ngIf="quizView == 'landing'">

    <quiz-landing
      [quiz]="quiz"
      (startQuiz)="startQuiz()"
      (closeQuiz)="closeQuiz()">
    </quiz-landing>

  </ng-container>

  <ng-container *ngIf="quizView == 'question'">

    <quiz-question
      [quiz]="quiz"
      [questions]="quizQuestions"
      (updateUserData)="updateUserData($event)"
      (closeQuiz)="closeQuiz()"
      (trackQuizEvent)="trackQuizEvent($event.type, $event.question)"
      (completeQuiz)="completeQuiz()">
    </quiz-question>

  </ng-container>

  <ng-container *ngIf="quizView == 'complete'">

    <quiz-complete
      [quiz]="quiz"
      [answeredCorrect]="answeredCorrect"
      [quizPassed]="quizPassed"
      [quizScore]="quizScore"
      (closeQuiz)="closeQuiz()"
      (retakeQuiz)="retakeQuiz()">
    </quiz-complete>

  </ng-container>

</div>