import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'quiz-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.sass']
})
export class CompleteComponent implements OnInit {
  @Input() quiz: any
  @Input() answeredCorrect: number | undefined
  @Input() quizScore: number | undefined
  @Input() quizPassed: boolean | undefined

  @Output('closeQuiz') closeQuiz: EventEmitter<any> = new EventEmitter()
  @Output('retakeQuiz') retakeQuiz: EventEmitter<any> = new EventEmitter()

  constructor() {}

  retake(): void {
    this.retakeQuiz.emit()
  }

  quit(): void {
    this.closeQuiz.emit()
  }

  ngOnInit(): void {}
}
