import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'WidgetSize'

export const props = {
  width: t.number,
  height: t.number
}

export const codec = t.strict(props, name)
