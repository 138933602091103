<footer fxLayout="column">
  <div
    class="footer-bottom"
    fxLayout="row"
    fxFlex.order="1"
    fxFlex.order.gt-xs="2"
  >
    <div class="f1" fxFlex="80"></div>
    <div class="f2" fxFlex="noshrink"></div>
    <div class="f3" fxFlex="5"></div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex.order="2"
    fxFlex.order.gt-xs="1"
  >
    <div class="disclaimer" fxFlex="90">
      <div class="disclaimer-text" fxLayout="row" fxLayoutAlign="center center">
        {{ language.general.disclaimer }}
      </div>
    </div>
    <div
      class="support"
      (click)="openSupport()"
      fxFlex="10"
      fxLayoutAlign="start center"
      fxFlex.gt-xs="auto"
    >
      <span *ngIf="!isPillar && !isEboni">Support</span>
      <span *ngIf="isPillar || isEboni">Technical Support</span>
    </div>
  </div>
</footer>
