<div
  [ngClass]="widget.disabled ? 'widget disabled' : 'widget'"
  (click)="selectWidget()"
>
  <div
    class="widget-bg"
    [ngStyle]="{
      'background-color': getBackgroundColor(),
      'background-image': background
    }"
  >
    <div class="widget-icon">
      <i
        class="{{ getIcon() }}"
        [ngStyle]="{ color: widget?.style?.iconColor }"
      ></i>
    </div>
    <div class="widget-centered">
      <div
        *ngIf="roadmapTitle"
        [ngStyle]="{ color: getTextColor() }"
        class="widget-label roadmap-title"
      >
        {{ widget.label }}
      </div>
      <div
        *ngIf="!roadmapTitle"
        [ngStyle]="{ color: getTextColor() }"
        class="widget-label"
      >
        {{ widget.label }}
      </div>
      <div
        *ngIf="widget.description"
        [ngStyle]="{ color: getDescriptionColor() }"
        class="widget-label widget-description"
      >
        {{ widget.description }}
      </div>
    </div>
  </div>
</div>
