export class DelphireUtilities {
  static normalizeName(name: string): string {
    const searchRegExp = /\s/g;
    const replaceWith = '-';
    const normalizedName: string = name
      .toLowerCase()
      .replace(searchRegExp, replaceWith);
    return normalizedName;
  }

  static isGuid(str: string): boolean {
    const validRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return validRegex.test(str);
  }
}
