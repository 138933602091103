import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import { environment } from '../../environments/environment'
import { Roadmap } from '../interfaces/roadmap'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { TaskService } from '../services/task.service'
import { AnalyticsService } from '../services/tracking/analytics.service'

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html'
})
export class RoadmapComponent implements OnInit, OnDestroy {
  roadmapReady: EventEmitter<boolean> = new EventEmitter()

  verbose: boolean = environment.production ? false : true
  roadmap: Roadmap = {
    pageViewType: ''
  }
  taskLookup: any = {
    all: <any>[],
    byDay: <any>[],
    byWeek: <any>[],
    byWeekByDay: <any>[]
  }
  objectives: any[] = []
  subscriber!: Subscription

  createTasks(): Promise<any> {
    return new Promise((resolve, reject) => {
      const promises: Promise<any>[] = []
      if (this.roadmap.tasks) {
        Object.values(this.roadmap.tasks).forEach((task) => {
          const promise: Promise<any> = this.taskService
            .get(task, 'RoadmapComponent')
            .then((t) => {
              this.taskLookup.all[task.id] = t

              if(task.dayNumber != null && typeof(task.dayNumber) != 'undefined') {
                if(!this.taskLookup.byDay[task.dayNumber]) this.taskLookup.byDay[task.dayNumber] = []
                this.taskLookup.byDay[task.dayNumber].push( t )
              }

              if(task.weekNumber != null && typeof(task.weekNumber) != 'undefined') {
                if(!this.taskLookup.byWeek[task.weekNumber]) this.taskLookup.byWeek[task.weekNumber] = []
                this.taskLookup.byWeek[task.weekNumber].push( t )
              }

              if(task.dayNumber != null && typeof(task.dayNumber) != 'undefined' && task.weekNumber != null && typeof(task.weekNumber) != 'undefined') {
                if(!this.taskLookup.byWeekByDay[task.weekNumber]) this.taskLookup.byWeekByDay[task.weekNumber] = []
                if(!this.taskLookup.byWeekByDay[task.weekNumber][task.dayNumber]) this.taskLookup.byWeekByDay[task.weekNumber][task.dayNumber] = []
                this.taskLookup.byWeekByDay[task.weekNumber][task.dayNumber].push( t )
              }
            })
          promises.push(promise)
        })
        Promise.all(promises)
          .then(() => {
            return resolve(true)
          })
          .catch((reason: any) => {
            console.log('error', reason)
            return reject(reason)
          })
      } else {
        return reject(false)
      }
    })
  }

  createObjectives(): void {
    this.objectives = []

    // loop each week and mess with the content at the top of the page (NOT THE TASKS)
    if(this.roadmap.weeks) {
      for(let pos=0; pos<this.roadmap.weeks.length; pos++) {
        let w = this.roadmap.weeks[pos]

        // flatten contentSections of each week into a lookup array called "objectives"
        if(w.contentSections) {
          for (let weeklyType in w.contentSections) {
            let s = w.contentSections[weeklyType]
            s.type = weeklyType
            s.weekNumber = w.number
            if (s.contents) {
              this.objectives.push(s)
            }
          }
        }

        // flatten listItems for each week
        if(this.objectives) {
          for(let i=0; i<this.objectives.length; i++) {
            let contents = this.objectives[i].contents
            this.objectives[i].sections = []
            if(contents) {
              // this.objectives[i].listItems = []
              for(let j=0; j<contents.length; j++) {
                let section = contents[j]
                let listItems = []
                if(section.type == 'list') {
                  if(section.value) {
                    for(let k=0; k<section.value.length; k++) {
                      listItems.push(section.value[k])
                      // this.objectives[i].listItems.push( section.value[k] )
                    }
                    section.listItems = listItems
                    this.objectives[i].sections.push(section)
                  }
                }
              }
            }
          }
        }
      }
    }



    if(this.verbose) { console.log('%c[ this.objectives ]', 'color: red', this.objectives) }
  };

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,

    private taskService: TaskService,
    private trackService: AnalyticsService

  ) {}

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    const roadmapId = String(routeParams.get('roadmapId'))
    const { databaseUrl } = this.localStorage.get('currentSpace')
    this.subscriber = this.fbS
      .db(databaseUrl)
      .object(environment.firebasePaths.space + 'roadmaps/' + roadmapId)
      .subscribe((roadmap) => {
        if(this.verbose) { console.log('%c[ roadmap ]', 'color: yellow', roadmap) }
        this.roadmap = roadmap
        this.createTasks()
          .then(() => {
            this.createObjectives()
            this.roadmapReady.emit(true)
          })
          .catch((error) => {
            console.error(error)
          })
      })
  }
  ngOnDestroy(): void {
    this.subscriber.unsubscribe()
  }
}
function CustomComponent(CustomComponent: any, arg1: { static: false }) {
  throw new Error('Function not implemented.')
}

