import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'LayoutGrid'

export const props = {
  enabled: t.nullable(t.boolean),
  columnWidth: t.number,
  gutterSize: t.number,
  rowHeight: t.number
}

export const codec = t.strict(props, name)
