import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, first, shareReplay } from 'rxjs/operators';

@Injectable()
export class CachingInterceptorService implements HttpInterceptor {
  public readonly store: Record<string, Observable<HttpEvent<any>>> = {};

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Don't cache if it's not cacheable
    if (req.method == 'POST') {
      if (req.url.indexOf('getDownloadUrl') > -1) {
        const { sourceKey } = req.body.params;

        let identifier = req.body.params.id;
        if (sourceKey) {
          identifier = identifier + '_' + sourceKey;
        }
        const cachedObservable =
          this.store[identifier] ||
          (this.store[identifier] = next.handle(req).pipe(
            // Filter since we are interested in caching the response only, not progress events
            filter((res) => res instanceof HttpResponse),
            // Share replay will cache the response
            shareReplay(1)
          ));
        return cachedObservable.pipe(first());
      } else {
        return next.handle(req);
      }
    } else {
      const cachedObservable =
        this.store[req.urlWithParams] ||
        (this.store[req.urlWithParams] = next.handle(req).pipe(
          // Filter since we are interested in caching the response only, not progress events
          filter((res) => res instanceof HttpResponse),
          // Share replay will cache the response
          shareReplay(1)
        ));
      // pipe first() to cause the observable to complete after it emits the response
      // This mimics the behaviour of Observables returned by Angular's httpClient.get()
      // And also makes toPromise work since toPromise will wait until the observable completes.
      return cachedObservable.pipe(first());
    }
  }
}
