import { Component, OnInit } from '@angular/core'
import { getApp } from '@angular/fire/app'
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where
} from '@angular/fire/firestore'
import { ActivatedRoute } from '@angular/router'
import { shuffle } from 'lodash'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { environment } from '../../environments/environment'
import { ProgressData } from '../interfaces/progress-data'
import { Quiz } from '../interfaces/quiz'
import { ConfirmationDialogService } from '../services/confirmation-dialog.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { NavigationService } from '../services/navigation.service'
import { ProgressService } from '../services/tracking/progress.service'
import { TrackService } from '../services/tracking/track.service'
import { TrackingService } from '../services/tracking/tracking.service'

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.sass'],
  providers: [{ provide: TrackingService, useClass: ProgressService }]
})
export class QuizComponent implements OnInit {
  verbose: boolean = environment.production ? false : true
  quiz: Quiz = {}
  quizId: string | undefined
  quizView: string = 'landing'
  answeredCorrect: number = 0
  quizAttempts: number = 0
  quizScore: number = 0
  quizPassed: boolean = false
  quizQuestions: Array<object> = []
  userProgression: Array<any> = []
  existingUserData: ProgressData = {}

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    // private afS: FireStoreServiceService,
    private navigation: NavigationService,
    private trackService: TrackService,
    private trackingService: TrackingService,
    private spinner: SpinnerVisibilityService,
    private dialogService: ConfirmationDialogService
  ) {}

  startQuiz(): void {
    this.quizView = 'question'
    this.trackQuizEvent('QUIZ_START')
  }

  updateUserData(data: any): void {
    this.userProgression.push(data)
    if (data.answeredCorrect) {
      this.answeredCorrect++
    }
  }

  retakeQuiz(): void {
    this.answeredCorrect = 0
    this.quizScore = 0
    this.quizPassed = false
    this.quizQuestions = []
    this.userProgression = []

    this.ngOnInit()
    this.quizView = 'landing'
  }

  closeQuiz(): void {
    if (this.userProgression.length == 0 || this.userProgression.length >= Number(this.quiz.questionCount))  {
      this.navigation.goBack()
    } else if (this.userProgression.length < Number(this.quiz.questionCount)) {
      const dialogOptions = {
        title: 'Are you sure you want to leave this quiz?',
        message: 'If you choose to leave before all of the questions are answered you will not receive credit for this quiz, though the attempt will still count.',
        cancelText: 'CANCEL',
        confirmText: 'YES, LEAVE QUIZ',
        id: 'quiz',
        panelClass: 'quizDialog'
      }

      this.dialogService.open(dialogOptions)

      this.dialogService.confirmed().subscribe((confirmed) => {
        if (confirmed) {
          this.navigation.goBack()
        }
      })
    }
  }

  completeQuiz(): void {
    this.quizScore = Math.floor(
      (this.answeredCorrect / Number(this.quiz.questionCount)) * 100
    )
    if (this.quizScore >= Number(this.quiz.passPercentage)) {
      this.quizPassed = true
      this.trackQuizEvent('QUIZ_COMPLETE')
    } else {
      this.trackQuizEvent('QUIZ_FAIL')
    }
    this.quizView = 'complete'
  }

  trackQuizEvent(type: string, question?: any): void {
    const params = {
      namespace: 'QuizProgress',
      id: this.quizId,
      originalKey: this.quizId,
      type: type,
      payload: {}
    }

    if(this.verbose) { console.log('%c[ this.existingUserData ]', 'color: red', this.existingUserData) }

    /*

    const guid = uuidv4()

    params.payload = {
      attemptNum: 2
      score: 100
      attempts: {
        GUID: {
          status: "QUIZ_COMPLETE"
          progression: []
          score: 100
        }
        GUID: {
          status: "QUIZ_FAIL"
          progression: []
          score: 50
        }
      }
    }

    */


    // if they have an attempt in progress
    if(this.existingUserData.payload?.attempts?.length) {

      // put the answers into the attempt
      this.existingUserData.payload.attempts[this.quizAttempts - 1] = { answers: this.userProgression }

      // if they're finishing it, give it a score inside the attempt
      if(type == 'QUIZ_COMPLETE' || type == 'QUIZ_FAIL') {
        this.existingUserData.payload.attempts[this.quizAttempts - 1].score = this.quizScore
      }

    } else {

      // if there's a payload but no attempts this is the first answer of the first attempt and we have to create everything inside payload.attempts
      if(this.existingUserData.payload) {
        this.existingUserData.payload.attempts = [ { answers: this.userProgression } ]

      // fu typescript
      } else {
        this.existingUserData.payload = {}
      }
    }

    switch (type) {
      case 'QUIZ_START':
        params.payload = {
          attemptNum: this.quizAttempts
        }
        break

      case 'QUIZ_ANSWER':
        if(this.verbose) { console.log('[ question ]', question) }
        let qData = this.userProgression[this.userProgression.length - 1]
        params.payload = {
          attemptNum: this.quizAttempts,
          // progression: this.userProgression
          attempts: this.existingUserData.payload.attempts,
          question_id: qData.question.id,
          question: qData.question.text,
          possibleAnswers: qData.question.answers,
          answered: this.getAnswers(qData.question.answers, qData.selectedAnswer),
          correct: qData.answeredCorrect
        }
        break

      case 'QUIZ_COMPLETE':
      case 'QUIZ_FAIL':

        let highestScoreSoFar = this.existingUserData.payload?.score || 0
        let newHighestScore = Math.max(highestScoreSoFar, this.quizScore)

        params.payload = {
          attemptNum: this.quizAttempts,
          score: newHighestScore,
          // progression: this.userProgression
          attempts: this.existingUserData.payload.attempts
        }
        break

      default:
        break
    }

    if (this.verbose) { console.log('%c[ QUIZ TRACKING EVENT ]', 'color: lime', params) }
    this.trackingService.addEvent(params)
  }

  getAnswers(possible: [], selected: []): any[] {
    let answers: any[] = []

    for (let index = 0; index < selected.length; index++) {
      answers.push(possible[index])
    }

    if ( answers.length === 1 ) {
      return answers[0]
    } else {
      return answers
    }

  } 

  loadQuiz(): void {
    if (this.verbose) { console.log('%c[ QUIZ ]', 'color: yellow', this.quiz) }

    this.spinner.hide()

    for (const q in this.quiz.questions) {
      this.quizQuestions.push(this.quiz.questions[<any>q])
    }

    this.quizQuestions = shuffle(this.quizQuestions)
    if (this.verbose) { console.log( '%c[ QUIZ QUESTIONS as ARRAY ]', 'color: yellow', this.quizQuestions )
    }
  }

  fetchQuizData = (): Promise<object> => {
    const { databaseUrl } = this.localStorage.get('currentSpace')

    return new Promise<object>((resolve, reject) => {
      this.fbS
        .db(databaseUrl)
        .object(environment.firebasePaths.space + 'quizzes/' + this.quizId)
        .subscribe((quiz) => {
          this.quiz = quiz
          resolve(quiz)
        })
    })
  }

  fetchUserData = (): Promise<number> => {
    const spaceId = this.localStorage.get('currentSpace').id
    const { user } = this.localStorage.get('delphireUser')
    return new Promise<number>((resolve, reject) => {
      const app = getApp()
      const firestore = getFirestore(app)
      const userDataRef = collection( firestore, 'userData/' + spaceId + '/progress' )
      const q = query(
        userDataRef,
        where('userId', '==', user.id),
        where('resourceId', '==', this.quizId)
      )
      getDocs(q).then((doc) => {
        if(this.verbose) { console.log('%c[ doc.empty ]', 'color: aqua', doc.empty) }
        // if(this.verbose) { console.log('%c[ doc ]', 'color: aqua', doc.docs[0].data()) }
        if (!doc.empty) {
          // if(this.verbose) { console.log('%c[ doc.docs[0].data() ]', 'color: red', doc.docs[0].data()) }
          this.existingUserData = doc.docs[0].data()
          if(this.verbose) { console.log('%c[ this.existingUserData ]', 'color: deeppink', this.existingUserData) }
          if(this.existingUserData) {
            if (this.existingUserData?.payload?.attempts?.length) {
              this.quizAttempts = this.existingUserData.payload.attempts.length + 1
            } else {
              this.quizAttempts = 1
            }
          } else {
            this.quizAttempts = 1
          }

          if(this.verbose) { console.log('%c[ this.existingUserData ]', 'color: red', this.existingUserData) }

          /*
          for (const d of doc.docs) {
            // if(this.verbose) { console.log('%c[ d.data() ]', 'color: red', d.data()) }
            this.existingUserData = d.data()
            const p: ProgressData = d as ProgressData
            if (this.existingUserData.payload.attempts.length) {
              this.quizAttempts = this.existingUserData.payload.attempts.length + 1
            }
          }
          */
          // resolve(this.quizAttempts)
        } else {
          this.quizAttempts = 1
        }
        resolve(this.quizAttempts)
      })
    })
  }

  /*
    $scope.db.collection("userData")
      .doc($scope.tenant.id)
      .collection("progress")
      .where("userId", "==", $rootScope.currentUser.id)
      .where("resourceId", "==", resourceId)
      .where("userDataType", "==", progressType)
      .get()
      .then((snapshot) =>
  */

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.quizId = String(routeParams.get('quizId'))
    this.trackService.setResourceType('quiz')

    this.spinner.show()

    const dataPromises = Promise.all<any>([
      this.fetchQuizData(),
      this.fetchUserData()
    ])

    dataPromises
      .then((value) => {
        if (this.verbose) { console.log('%c[ PROMISE VALUE ]', 'color: yellow', value) }
      })
      .catch((error) => {
        if (this.verbose) { console.error('[ PROMISE REJECTED ]', error) }
      })
      .finally(() => {
        if (this.verbose) { console.log('%c[ PROMISE COMPLETED ]', 'color:lime') }
        this.loadQuiz()
      })
  }
}
