import { brand, Branded, string, success, Type } from 'io-ts'
import { Either, IO, pipe } from '../fp-ts'
import { Any, nullType, union } from './io-ts'
import { fromNullable } from './io-ts-types'

export function nullable<T extends Any>(type: T) {
  const inner = union([type, nullType])
  return fromNullable(inner, null, `Nullable(${type.name})`)
}

export function fromNullableIO<A, O, I>(
  type: Type<A, O, I>,
  io: IO.Type<A>,
  name: string
) {
  return new Type<A, O, I>(
    name,
    type.is,
    (i, c) => (i == null ? success(io()) : type.validate(i, c)),
    type.encode
  )
}

export const urlFromString = new Type<URL, string, unknown>(
  'UrlFromString',
  (a): a is URL => a instanceof URL,
  (i, c) =>
    pipe(
      string.decode(i),
      Either.chain((s) => {
        try {
          return Either.right(new URL(s))
        } catch (e) {
          return Either.left([
            {
              value: s,
              context: c,
              message: (e as { message?: string }).message
            }
          ])
        }
      })
    ),
  (a) => a.toString()
)

export interface NonWhitespaceStringBrand {
  readonly NonWhitespaceString: unique symbol
}

export type NonWhitespaceString = Branded<string, NonWhitespaceStringBrand>

export const isNonWhitespaceString = (s: string): s is NonWhitespaceString =>
  /\S/.test(s)

export const nonWhitespaceString = brand(
  string,
  isNonWhitespaceString,
  'NonWhitespaceString'
)
