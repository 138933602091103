import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'LinkType'

export const codec = t.keyof(
  {
    ['']: null,
    ['Agenda']: null,
    ['Course']: null,
    ['External Link']: null,
    ['Fluency Tool']: null,
    ['Library']: null,
    ['Modal']: null,
    ['None']: null,
    ['Quiz']: null,
    ['Resource']: null,
    ['Roadmap']: null,
    ['Slideshow']: null,
    ['Task List']: null,
    ['Verbalizer']: null
  },
  name
)
