<div class="home" [ngStyle]="{
    height: '100%',
    padding: '5px 2px 105px 2px'
  }">
  <router-outlet (activate)="outletActivated()" (deactivate)="outletDeactivated()"></router-outlet>
  <div class="home-view" *ngIf="shouldShowHomePage" fxLayout="column">
    <div class="hero" [ngStyle]="{
        'background-image': 'url(' + heroBackgroundUrl + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center left'
      }" fxFlex="33" fxLayout="row" fxLayoutGap="4" fxLayoutAlign="space-between center">
      <div class="userInfo" fxLayoutGap="4" fxFlex="100" fxLayoutAlign="space-between left" fxLayout="column">
        <p class="login">
          Last login was on
          {{ user?.updated_at | date: 'EEE, MMM dd, y, h:mm a' }}
        </p>
        <h1>Welcome, {{ user?.given_name }}!</h1>

        <div class="info-container" fxLayout="row">
          <div fxLayout="row">
            <img [src]="user?.picture" referrerpolicy="no-referrer" mat-card-avatar />
          </div>

          <div fxLayout="column" style="padding-top: 11px;">
            <p style="
                margin-bottom: 0px;
                color: black;
                font-family: 'Roboto';
                font-size: .85em;
                margin-bottom: 5px;
              ">
              Displaying Content for:
            </p>
            <span class="groupname">{{ groupName }}</span>
          </div>
        </div>
      </div>
      <div fxHide.lt-md class="userInfo" fxLayoutGap="4" fxFlex="25" fxLayoutAlign="space-between right"
        fxLayout="column" *ngIf="lastTaskInProgress && lastTaskInProgress.id !== ''">
        <h2>Pick Up Where I Left Off...</h2>
        <app-task-card [item]="lastTaskInProgress"></app-task-card>
      </div>
    </div>
    <ng-container *ngIf="landingPageSections?.sections">
      <ng-container *ngFor="let section of landingPageSections.sections">
        <div class="inProgress" flexLayout="row" fxFlex="100" *ngIf="librariesLoaded">
          <h2 style="display: inline-flex" *ngIf="section.type == 'progress'" [innerHtml]="
              progressType === 'In Progress' ? 'In Progress' : 'Completed Items'
            "></h2>
          <h2 *ngIf="section.type == 'favorites'">My Favorites</h2>
          <h2 *ngIf="section.type == 'spaces'">Other Spaces</h2>
          <h2 *ngIf="section.type == 'library'">
            {{ libraries | libraryName: section.libraryId }}
          </h2>
          <button mat-button (click)="toggleProgress()" class="toggleProgress"
            *ngIf="section.type == 'progress' && lastTaskInProgress && lastTaskInProgress.id !== ''" [innerHtml]="
              progressType === 'In Progress'
                ? 'Display Completed Items'
                : 'Display In Progress Items'
            "></button>
          <div fxLayout="row" class="scrollList" [ngSwitch]="section.type" #scrollView #inProgress>
            <div appShowMore [parentElem]="scrollView" direction="left"
              [ngClass]="section.type === 'spaces' ? 'less spaces' : 'less'">
              <ng-icon name="hero-chevron-left" size="25px"></ng-icon>
            </div>
            <!-- Progress Cards -->
            <ng-container *ngSwitchCase="'progress'">
              <ng-container *ngIf="userTasksInProgress?.length == 0">
                <img style="width: 303px; height: 187px" src="images/placeholder-noprogress-2x.png" />
              </ng-container>
              <ng-container *ngFor="let item of userTasksInProgress">
                <app-task-card [item]="item" [progressType]="progressType" [section]="'progress'"
                  (newTaskEvent)="newTaskSelected($event)" *ngIf="!item.archived">
                </app-task-card>
              </ng-container>
            </ng-container>
            <!-- Favorites Cards -->
            <ng-container *ngSwitchCase="'favorites'">
              <ng-container *ngIf="favoritesArray.length == 0">
                <img style="width: 303px; height: 187px" src="images/placeholder-nofavs-2x.png" />
              </ng-container>
              <ng-container *ngIf="favoritesArray.length">
                <ng-container *ngFor="let favorite of favoritesArray">
                  <app-task-card [section]="'favorites'" [item]="favorite">
                  </app-task-card>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- Libraries -->
            <ng-container *ngSwitchCase="'library'">
              <ng-container *ngIf="section.type == 'library'">
                <ng-container *ngFor="let item of libraryTasksByKey | keyvalue">
                  <ng-container *ngIf="item.key == section.libraryId">
                    <ng-container *ngFor="let task of libraryTasksByKey[section.libraryId]">
                      <app-task-card [item]="task" [section]="'library'" (newTaskEvent)="newTaskSelected($event)">
                      </app-task-card>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- Spaces -->
            <ng-container *ngSwitchCase="'spaces'">
              <ng-container *ngFor="let space of spaces | orderBy: 'name':true">
                <div class="space" (click)="selectSpace(space.id)">
                  <img mat-card-image [src]="space.imageUrl | async" />
                </div>
              </ng-container>
            </ng-container>
            <div appShowMore [parentElem]="scrollView" direction="right"
              [ngClass]="section.type === 'spaces' ? 'more spaces' : 'more'">
              <ng-icon name="hero-chevron-right" size="25px"></ng-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>