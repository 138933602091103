import { Injectable } from '@angular/core'
import {
  collection,
  CollectionReference,
  doc,
  Firestore,
  getDoc
} from '@angular/fire/firestore'
import { Group } from '@delphire/dto'
import { Either, pipe, t } from '@delphire/fp'

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  readonly groupCollection: CollectionReference

  constructor(readonly firestore: Firestore) {
    this.groupCollection = collection(firestore, 'groups')
  }

  async read(id: string): Promise<Group.Type | null> {
    const groupDoc = doc(this.groupCollection, id)

    const groupSnap = await getDoc(groupDoc)
    return pipe(
      groupSnap.data(),
      Group.codec.decode,
      Either.mapLeft(t.PathReporter.failure),
      Either.fold(
        (errors) => {
          console.error(`Could not decode group`, errors)
          return null
        },
        (result) => {
          return result
        }
      )
    )
  }
  async getGroupById(id: string): Promise<Group.Type | null> {
    if (typeof id !== 'string') return null

    const groupDoc = await this.read(id)

    if (groupDoc == null) return null

    return { ...groupDoc }
  }
}
