import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'LinkParameters'

export const props = {
  agendaId: t.nullable(t.string),
  courseId: t.nullable(t.string),
  taskListId: t.nullable(t.string),
  externalLinkId: t.nullable(t.string),
  url: t.nullable(t.string),
  fluencyToolId: t.nullable(t.string),
  libraryId: t.nullable(t.string),
  modalId: t.nullable(t.string),
  quizId: t.nullable(t.string),
  resourceId: t.nullable(t.string),
  roadmapId: t.nullable(t.string),
  slideshowId: t.nullable(t.string),
  verbalizerId: t.nullable(t.string)
}

export const codec = t.strict(props, name)

export const empty: Type = {
  agendaId: null,
  courseId: null,
  taskListId: null,
  externalLinkId: null,
  url: null,
  fluencyToolId: null,
  libraryId: null,
  modalId: null,
  quizId: null,
  resourceId: null,
  roadmapId: null,
  slideshowId: null,
  verbalizerId: null
}

export const codecFromNullable = t.fromNullable(codec, empty)
