import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'

@Pipe({
  name: 'from'
})
export class FromPipe implements PipeTransform {
  transform(value: string): any {
    const date = moment(value)
    return date.fromNow()
  }
}
