import { t } from '@delphire/fp'
import { TenantEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Tag'

export const props = { ...TenantEntity.props }

export const codec = t.strict(props, name)
