import { t } from '@delphire/fp'
import { IAMEntity } from '../data'
export type Type = t.TypeOf<typeof codec>

export const name = 'User'

export const props = {
  ...IAMEntity.props,
  email: t.string,
  givenName: t.nullable(t.string),
  familyName: t.nullable(t.string),
  employmentStartDate: t.nullable(t.DateFromISOString)
}

export const codec = t.strict(props, name)
