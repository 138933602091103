<div class="new-ui notifications fadeview">
  <div class="notifications-name">
    <h2>Notifications</h2>
  </div>

  <mat-list>
    <mat-list-item *ngFor="let notification of notifications">
      <div
        class="mat-list-item-text"
        (click)="
          handleOpenLink(
            notification.itemId,
            notification.id,
            notification.type,
            notification.tenantId
          )
        "
      >
        <!-- <div fxFlex="33">
          <img [src]="notification.spaceImage | async" class="grav" />
        </div> -->

        <h3>
          <span class="favorites-icon">
            <i class="fas fa-bell"></i>
          </span>
          {{ notification.title }}
        </h3>
        <p
          style="
            display: inline-flex;
            align-content: center;
            align-items: center;
          "
        >
          <span class="favorites-icon">
            <i
              class="fas fa-circle"
              [ngStyle]="{
                'visibility': notification.read == true ? 'hidden' : 'visible',
                'margin-right': notification.read == true ? '8px' : '8px',
                'color': 'hsl(214deg 100% 59%)',
                'font-size': '10px',
                'display': 'flex'
              }"
            ></i>
          </span>
          {{ notification.body }}
        </p>
      </div>
      <button
        class="mat-button mat-secondary"
        aria-label="Delete"
        (click)="deleteNotification(notification)"
      >
        <i class="fas fa-trash"></i>
      </button>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>
