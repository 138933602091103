import { t } from '@delphire/fp'
import { Timestamps } from '../data'
import { Link } from '../link'

export type Type = t.TypeOf<typeof codec>

export const name = 'SlideshowSlide'

export const props = {
  ...Timestamps.props,
  name: t.fromNullable(t.string, ''),
  link: t.nullable(Link.codec),
  order: t.number,
  resourceId: t.nullable(t.string)
}

export const codec = t.strict(props, name)

export const codecRecord = t.record(t.string, codec)

export const codecRecordFromNullable = t.fromNullable(codecRecord, {})
