import { Component, OnInit } from '@angular/core'

import { LocalStorageService } from '../services/local-storage-service.service'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass']
})
export class PageNotFoundComponent implements OnInit {
  constructor(private localStorage: LocalStorageService) {}

  last?: string
  ngOnInit(): void {
    this.last = this.localStorage.get('lastLocation')
    if (this.last) {
      this.localStorage.remove('lastLocation')
      this.last = undefined
      window.location.reload()
    }
  }
}
