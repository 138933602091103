import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

import { Space } from '../interfaces/space'
import { LocalStorageService } from './local-storage-service.service'

@Injectable({
  providedIn: 'root'
})
export class DynamicLinkService {
  currentSpace: Space = this.localStorage.get('currentSpace')
  domainUriPrefix: string = environment.authConfig.audience.replace(
    'iam',
    'app'
  )

  link!: string
  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) {}

  async postRequest(data: object): Promise<Observable<HttpResponse<any>>> {
    const headers = {
      'Content-Type': 'application/json'
    }
    const requestUrl =
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks'

    return this.http.post<any>(requestUrl, data, {
      headers: headers,
      observe: 'response',
      responseType: 'json',
      params: { key: environment.firebaseConfig.apiKey }
    })
  }
  async generateLink() {
    this.link = window.location.href
    if (this.domainUriPrefix.indexOf('atp.gene') > -1) {
      this.domainUriPrefix = this.domainUriPrefix.replace('atp.gene', 'atpgene')
    }
    if (Capacitor.isNativePlatform()) {
      this.link = this.link.replace('capacitor', 'https')
    }
    const data: any = {
      dynamicLinkInfo: {
        domainUriPrefix: this.domainUriPrefix,
        link: this.link,
        iosInfo: {
          iosBundleId: environment.appId,
          iosFallbackLink: environment.firebaseConfig.fallbackUrl,
          iosCustomScheme: environment.urlScheme
        }
      }
    }

    const result = await this.postRequest(data)
    return new Promise<string>((resolve, reject) => {
      result.subscribe(
        (linkData: any) => {
          resolve(linkData.body.shortLink)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
