import { t } from '@delphire/fp'
import * as LayoutLayout from './LayoutLayout'

export type Type = t.TypeOf<typeof codec>

export const name = 'LayoutLayouts'

export const props = {
  lg: LayoutLayout.codecArrayFromNullable,
  md: LayoutLayout.codecArrayFromNullable,
  xs: LayoutLayout.codecArrayFromNullable,
  xxs: LayoutLayout.codecArrayFromNullable
}

export const codec = t.strict(props, name)

export const empty: Type = {
  lg: [],
  md: [],
  xs: [],
  xxs: []
}

export const codecFromNullable = t.fromNullable(codec, empty)
