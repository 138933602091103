import { Component, EventEmitter, Input, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { Task } from '../../../app/interfaces/task';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../interfaces/user-info';
import { LocalStorageService } from '../../services/local-storage-service.service';
import { RoadmapWeek } from '../interfaces/roadmap-week';

@Component({
  selector: 'roadmap-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.sass']
})
export class DailyRoadmap implements OnInit {
  @Input() roadmap: any
  @Input() taskLookup: any
  @Input() roadmapReady!: EventEmitter<boolean>
  @Input() objectives: any[] = []

  verbose: boolean = environment.production ? false : true

  currentUser: UserInfo = {}
  currentDate: any = moment()
  currentEmployeeDay: any = ''
  private _currentWeekNum: number = 0 // using setter to also call gotoWeek
  private _currentDayNum: number = 0  // using setter to also call gotoDay
  roadmapEndDay: any = ''
  employeeStartDate: any
  weekDays: string[] = []
  smalltabs: boolean = false

  currentWeek: RoadmapWeek = { title: '', subtitle: '' }
  filteredTasks: Task[] = []

  constructor(
    private localStorage: LocalStorageService
  ) { }

  //~ ------------------------------------ INIT ------------------------------------

  ngOnInit(): void {
    if(this.verbose) { console.log('%c[ this.objectives ]', 'color: whitesmoke', this.objectives) }
    const { user } = this.localStorage.get('delphireUser')
    this.currentUser = user
    if(this.verbose) { console.log('%c[ user ]', 'color: lime', this.currentUser ) }
    if (this.roadmapReady) {
      this.roadmapReady.subscribe(ready => {
        this.init()
      })
    }
  }

  init(): void {
    if(this.verbose) { console.log('%c[ DAILY ROADMAP INIT ]', 'color: deeppink') }
    this.calculateDates()

    if(this.roadmap.totalDaysPerWeek == 5) {
      this.weekDays = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5' ]
    } else {
      this.smalltabs = true
      this.weekDays = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7']
    }
    if(this.verbose) { console.log('%c[ this.weekDays ]', 'color: red', this.weekDays) }
  }

  //~ ------------------------------------ GETTERS / SETTERS / WATCHERS ------------------------------------

  ngOnChanges(changes: SimpleChanges) {
    if(changes['objectives']) {
      if(this.verbose) { console.log('%c[ changes ]', 'color: deeppink', changes) }
      if(this.verbose) { console.log('%c[ changes[objectives] ]', 'color: red', changes['objectives']) }
      this.objectives = changes['objectives'].currentValue
      this.init()
    }
  }

  set currentWeekNum(val:number) {
    this._currentWeekNum = val
    this.gotoWeek(val)
    this.currentDayNum = 0
  }

  get currentWeekNum():number {
    return this._currentWeekNum
  }

  // ---------

  set currentDayNum(val:number) {
    this._currentDayNum = val
    this.gotoDay(val)
  }

  get currentDayNum():number {
    return this._currentDayNum
  }

  //~ ------------------------------------ WEEK CHANGE  ------------------------------------

  gotoWeek(weekNum: number): void {
    this.filteredTasks = this.taskLookup.byWeek[this.currentWeekNum]
    if(this.filteredTasks) {
      this.filteredTasks.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1)
    }
    if(this.objectives) {
      this.currentWeek = this.objectives[weekNum] ? this.objectives[weekNum] : { title: '', subtitle: '' }
    }
  }

  // ----------------------------------------------

  gotoDay(dayNum: number): void {
    if(this.verbose) { console.log('%c[ this.currentWeekNum ]', 'color: whitesmoke', this.currentWeekNum) }
    if(this.verbose) { console.log('%c[ this.currentDayNum ]', 'color: whitesmoke', this.currentDayNum) }
    if(this.taskLookup.byWeekByDay && this.taskLookup.byWeekByDay[this.currentWeekNum] && this.taskLookup.byWeekByDay[this.currentWeekNum][this.currentDayNum]) {
      if(this.verbose) { console.log('%c[ this.taskLookup.byWeekByDay[this.currentWeekNum][this.currentDayNum] ]', 'color: whitesmoke', this.taskLookup.byWeekByDay[this.currentWeekNum][this.currentDayNum]) }
      this.filteredTasks = this.taskLookup.byWeekByDay[this.currentWeekNum][this.currentDayNum]
    } else {
      if(this.verbose) { console.log('%c[ NO TASKS YET ]', 'color: red') }
    }
    if(this.filteredTasks) {
      this.filteredTasks.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1)
    }
    if(this.objectives) {
      this.currentWeek = this.objectives[this.currentWeekNum] ? this.objectives[this.currentWeekNum] : { title: '', subtitle: '' }
    }
  }

  //~ ------------------------------------ DATE CALCULATIONS ------------------------------------

  calculateDates(): void {

    switch (this.roadmap.startDateFormat) {
      case 'event':
        this.employeeStartDate = moment(this.roadmap.startDate)
        break
      default:
        this.employeeStartDate = moment(this.currentUser.employmentStartDate)
    }

    this.currentDate = moment()
    this.currentEmployeeDay = this.currentDate.diff(this.employeeStartDate, 'days')
    this.roadmapEndDay = moment(this.employeeStartDate).add(this.roadmap.weeks.length * 7, 'days')

    // if(this.verbose) { console.log('%c[ this.roadmap ]', 'color: red', this.roadmap) }
    // if(this.verbose) { console.log('%c[ this.employeeStartDate ]', 'color: red', this.employeeStartDate) }
    // if(this.verbose) { console.log('%c[ this.currentUser.employmentStartDate ]', 'color: red', this.currentUser.employmentStartDate) }
    // if(this.verbose) { console.log('%c[ this.currentEmployeeDay ]', 'color: red', this.currentEmployeeDay) }
    // if(this.verbose) { console.log('%c[ this.currentWeekNum ]', 'color: red', this.currentWeekNum) }
    // if(this.verbose) { console.log('%c[ this.currentDayNum ]', 'color: red', this.currentDayNum) }
    // if(this.verbose) { console.log('%c[ this.roadmapEndDay ]', 'color: red', this.roadmapEndDay) }

    if (this.currentDate <= this.employeeStartDate || this.currentDate >= this.roadmapEndDay) {
      this.currentWeekNum = 0
      this.currentDayNum = 0
    } else {
      this.currentWeekNum = this.currentDate.diff(this.employeeStartDate, 'weeks')
      this.currentDayNum = this.currentWeekNum >= 1 ? this.currentEmployeeDay % 7 : this.currentEmployeeDay
    }
    // if(this.verbose) { console.log('%c[ this.currentWeekNum ]', 'color: lime', this.currentWeekNum ) }
    // if(this.verbose) { console.log('%c[ this.currentDayNum ]', 'color: lime', this.currentDayNum ) }
  }

}
