import moment from 'moment'
import { ActivatedRoute } from '@angular/router'
import { Agenda, AgendaConfig, AgendaEvent } from '../interfaces/agenda'
import { Component, OnInit } from '@angular/core'
import { DelphireLinkService } from '../services/delphire-link.service'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { environment } from 'src/environments/environment'
import { FirebaseService } from '../services/firebase.service'
import { getApp } from 'firebase/app'
import { LocalStorageService } from '../services/local-storage-service.service'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { User } from '../interfaces/user'
import 'moment-timezone'

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.sass']
})
export class AgendaComponent implements OnInit {

  verbose: boolean = environment.production ? false : true
  user: User
  agendaId: string
  agenda: Agenda = {}
  agendaTimezone: string
  userTimezone: string
  timezoneOffset: number = 0
  userAgenda: Agenda = {}
  userEvents: AgendaEvent[] = []
  filteredUserEvents: AgendaEvent[] = []
  locations: any = {}
  meetingDuration: number
  viewingDay: number = 0
  viewingDate: moment.Moment
  link: any
  resource: any
  resourceExists: boolean
  agendaConfig: AgendaConfig = environment.appLanguage.agenda

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    private linkService: DelphireLinkService,
    private spinner: SpinnerVisibilityService
  ) { }


  // ~-----------------------------------------------------------------------------------------------------~


  ngOnInit(): void {

    if(this.verbose) { console.log('%c[ agendaConfig ]', 'color: red', this.agendaConfig) }

    const routeParams = this.route.snapshot.paramMap
    this.agendaId = String(routeParams.get('agendaId'))

    const delphireUser = this.localStorage.get('delphireUser')
    this.user = delphireUser.user
    this.user.groupId = delphireUser.groupId
    if(this.verbose) { console.log('%c[ current user ]', 'color: yellow', this.user) }

    this.userTimezone = moment.tz.guess()
    if(this.verbose) { console.log('%c[ user timezone ]', 'color: orangered', this.userTimezone) }

    this.spinner.show()

    const dataPromises = Promise.all<any>([
      this.fetchAgendaData(),
      this.fetchLocationData()
    ])

    dataPromises
      .then((value) => {
        if (this.verbose) { console.log('%c[ PROMISE VALUE ]', 'color: yellow', value) }
      })
      .catch((error) => {
        if (this.verbose) { console.error('[ PROMISE REJECTED ]', error) }
      })
      .finally(() => {
        if (this.verbose) { console.log('%c[ PROMISE COMPLETED ]', 'color:lime') }
        this.parseAgenda().then(() => this.loadAgenda())
        // this.parseAgenda('-LsSLHG8-3AwLv7Bxo3X').then(() => this.loadAgenda()) //! PASS TEST USER
      })

  }


  // ~-----------------------------------------------------------------------------------------------------~


  fetchAgendaData = (): Promise<object> => {
    const { databaseUrl } = this.localStorage.get('currentSpace')

    return new Promise<object>((resolve, reject) => {
      this.fbS
        .db(databaseUrl)
        .object(environment.firebasePaths.space + 'agendas/' + this.agendaId)
        .subscribe((agenda) => {
          switch (agenda.timezone) {
            case 'US/Eastern'  : this.agendaTimezone = 'America/New_York'    ; break;
            case 'US/Central'  : this.agendaTimezone = 'America/Chicago'     ; break;
            case 'US/Mountain' : this.agendaTimezone = 'America/Denver'      ; break;
            case 'US/Pacific'   : this.agendaTimezone = 'America/Los_Angeles' ; break;
            default: break
          }
          this.agenda = agenda
          if(this.verbose) { console.log('%c[ agenda timezone ]', 'color: orangered', this.agendaTimezone) }
          resolve(agenda)
        })
      })
  }


  // ~-----------------------------------------------------------------------------------------------------~


  fetchLocationData = (): Promise<object> => {
    const { databaseUrl } = this.localStorage.get('currentSpace')

    return new Promise<object>((resolve, reject) => {
      this.fbS
      .db(databaseUrl)
      .object(environment.firebasePaths.space + 'locations/')
      .subscribe((locations) => {
        this.locations = locations
        resolve(locations)
      })
    })
  }


  // ~-----------------------------------------------------------------------------------------------------~


  parseAgenda = (userId: string | undefined = this.user.id): Promise<object> => {
    if(this.verbose) { console.log('%c[ Agenda User ]', 'color: lime', userId) }
    if(this.verbose) { console.log('%c[ parseAgenda() : RAW agenda ]', 'color: lime', this.agenda) }
    if(this.verbose) { console.log('%c[ parseAgenda() : Day ]', 'color: lime', this.viewingDay) }
    if(this.verbose) { console.log('%c[ this.locations ]', 'color: lime', this.locations) }

    this.setStartingDay()

    // * make copy of raw agenda
    this.userAgenda = { ...this.agenda }

    // * convert start and end date to moment to get duration
    let startDate = moment(this.agenda.startDate)
    let endDate = moment(this.agenda.endDate)

    // * get the duration of the meeting in days
    this.meetingDuration = endDate.diff(startDate, 'days') +1
    if(this.verbose) { console.log('%c[ meeting Duration ]', 'color: yellow', this.meetingDuration) }

    // * create promise
    return new Promise<object>((resolve, reject) => {

      // * If the agenda has links
      if( this.agenda.links) {
        for (const [id, link] of Object.entries(this.agenda.links)) {
          this.checkLink(link)
          this.link = link
        }
      }

      // * If the agenda has events
      if( this.agenda.events ) {

        // * loop through all events
        for (const [id, event] of Object.entries(this.agenda.events)) {

          // * if there is access level controll (acl) on the event
          if(event.acl) {

            // * loop to get acl for users and groups
            for (const [type, acl] of Object.entries(event.acl)) {

              // * push event if userId or GroupId in acl and user not excluded
              if(acl) {
                for (const [id, bool] of Object.entries(acl)) {
                  if(userId == id && bool || this.user.groupId == id) this.userEvents.push(event)
                }

              }
            }
          }

          // * push all events without access level control
          if(!event.acl) this.userEvents.push(event)

        }

        // * get day as number in month
        let agendaDay = +(moment.tz(this.agenda.startDate, "Etc/UTC").format('D'))

        // console.log('%c[ agendaDay ]', 'color: red', agendaDay)

        // * loop for each day in the meeting
        for (let i = 0; i < this.meetingDuration; i++) {

          // * loop all user events
          for (const [id, event] of Object.entries(this.userEvents)) {

            let eventDay = +(moment.tz(event.startDate, "Etc/UTC").format('D'))
            // console.log('---')
            // console.log('%c[ eventDay ]', 'color: red', eventDay, event.name)

            // * write the day of the event to the obj
            if( eventDay == agendaDay) {
              event.day = i
            }

            // * convert the time and write to obj for sorting
            event.epoch = moment(event.startDate).unix()
          }
          agendaDay++
        }

        // * sort by converted time
        this.userEvents.sort( (a: any, b: any) => a.epoch  - b.epoch )

        // * filter events by day
        this.filterEventsByDay()

      }

      resolve(this.userAgenda)

    })

  }

  filterEventsByDay(): void {
    this.filteredUserEvents = this.userEvents.filter( (event: AgendaEvent) => event.day == this.viewingDay)
    if(this.verbose) { console.log('%c[ filteredUserEvents ]', 'color: lime', this.filteredUserEvents) }
  }


  // ~-----------------------------------------------------------------------------------------------------~


  setStartingDay(): void {
    let start = moment(this.agenda.startDate)
    let today = moment()
    this.viewingDay = today.diff(start, 'days')
    this.viewingDate = moment(this.agenda.startDate).add(this.viewingDay, 'd')

    // * set day to first day of meeting if the event is not currently in progress
    if (this.viewingDay < 0 || this.viewingDay > this.meetingDuration) {
      this.viewingDay = 0
      this.viewingDate = moment(this.agenda.startDate)
    }
  }


  setTimezoneOffset(): void {
    if(this.filteredUserEvents.length) {
      let ts = this.filteredUserEvents[0].startDate

      let x = moment.tz(ts, this.userTimezone).utcOffset()
      let y = moment.tz(ts, this.agendaTimezone).utcOffset()

      this.timezoneOffset = x-y

      if(this.verbose) { console.log('%c[ timezone offset ]', 'color: yellow', this.timezoneOffset) }
    }
  }


  loadAgenda(): void {
    if(this.verbose) { console.log('%c[ loadAgenda() ]', 'color: yellow', this.userAgenda) }
    this.setTimezoneOffset()
    this.setStartingDay()
    this.spinner.hide()
  }


  getLocationName(id: string): string {
    if(id == 'None') return 'None'
    return this.locations[id].name
  }


  getEventTime(date: string): string {
    return moment.utc(date).utcOffset(this.timezoneOffset).format('h:mm A')
  }


  previousDay(): void {
    if(this.viewingDay > 0) {
      this.viewingDay--
      this.viewingDate = moment(this.viewingDate).subtract(1, 'd')
      this.filterEventsByDay()
    }
  }


  nextDay(): void {
    if(this.viewingDay < (this.meetingDuration -1)) {
      this.viewingDay++
      this.viewingDate = moment(this.viewingDate).add(1, 'd')
      this.filterEventsByDay()
    }
  }


  formatDay(date: moment.Moment): string {
    return moment(date).format('dddd, MMMM Do, YYYY')
  }


  filterDay(event: AgendaEvent): boolean {
    return event.day == this.viewingDay
  }


  checkLink(resource: any): void {
    const app = getApp()
    const firestore = getFirestore(app)
    const q = doc(firestore, 'resources' + '/' + resource.parameters.resourceId)
    getDoc(q).then((doc) => {
      if (doc.exists()) {
        this.resourceExists = true
        this.resource = doc.data()
      }
    })

  }


  async handleLink(): Promise<void> {
    if(this.verbose) { console.log('[ handleLink() : resource ]', this.resource) }
    this.linkService.handleLink({
      type: this.resource.type,
      resource: this.resource,
      route: this.route.parent
    })
  }


}
