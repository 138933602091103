import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor() { }

  getIcon(type: string, solid?: boolean): string {

    //* remove whitespace and lowercase
    type = type.replace(/\s+/g, '').toLowerCase()

    let icon: string

    switch (type) {
      case 'quiz'          : icon = 'HeroAcademicCap'    ; break;
      case 'course'        : icon = 'HeroCollection'     ; break;
      case 'fluency'       : icon = 'HeroCollection'     ; break;
      case 'module'        : icon = 'HeroCube'           ; break;
      case 'package'       : icon = 'HeroCube'           ; break;
      case 'document'      : icon = 'HeroDocumentText'   ; break;
      case 'duplicate'     : icon = 'HeroDuplicate'      ; break;
      case 'externallink'  : icon = 'HeroExternalLink'   ; break;
      case 'library'       : icon = 'HeroLibrary'        ; break;
      case 'roadmap'       : icon = 'HeroMap'            ; break;
      case 'audio'         : icon = 'HeroMicrophone'     ; break;
      case 'image'         : icon = 'HeroPhotograph'     ; break;
      case 'favorite'      : icon = 'HeroStar'           ; break;
      case 'layout'        : icon = 'HeroTemplate'       ; break;
      case 'verbalizer'    : icon = 'HeroUsers'          ; break;
      case 'video'         : icon = 'HeroVideoCamera'    ; break;
      case 'chat'          : icon = 'HeroChat'           ; break;
      case 'flag'          : icon = 'HeroFlag'           ; break;
      case 'badge-check'   : icon = 'HeroBadgeCheck'     ; break;
      default              : icon = 'HeroFire'           ; break;
    }

    if (solid) icon = icon + 'Solid'

    return icon
  }
}
