import { Injectable } from '@angular/core'
import { FirebaseApp, getApp } from '@angular/fire/app'
import {
  collection,
  collectionSnapshots,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getFirestore,
  query,
  setDoc
} from '@angular/fire/firestore'
import { Params } from '@angular/router'
import _ from 'lodash'
import moment from 'moment'
import { Observable, of, Subject } from 'rxjs'

import { Disclaimer } from '../interfaces/disclaimer'
import { Favorite } from '../interfaces/favorite'
import { Resource } from '../interfaces/resource'
import { ConfirmationDialogService } from './confirmation-dialog.service'
import { LocalStorageService } from './local-storage-service.service'

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  app: FirebaseApp = getApp()
  firestore: Firestore = getFirestore(this.app)

  favorites: Favorite[] = []

  numFavorites: number = 0

  verbose = true

  public favorites$: Subject<Observable<Favorite[]>> = new Subject()

  async isFavorite(id: string) {
    const fav = _.find(this.favorites, {
      id: id
    })
    if (fav) {
      return true
    } else {
      return false
    }
  }

  async getFavoriteResources(params: Params) {
    const { id, favType } = params
    let node: string = 'resources'
    switch (favType) {
      case 'agenda':
        node = 'agenda'
        break
      case 'course':
        node = 'courses'
        break
      case 'external link':
        node = 'externalLinks'
        break
      case 'library':
        node = 'libraries'
        break
      case 'roadmap':
        node = 'roadmaps'
        break
      case 'quiz':
        node = 'quizzes'
        break
      case 'verbalizer':
        node = 'verbalizers'
        break
      default:
        node = 'resources'
        break
    }

    const docRef = doc(this.firestore, node + '/' + id)
    const resource = getDoc(docRef)
    return (await resource).data() as Resource
  }

  favorite(id: string): Observable<boolean> {
    const observable: Observable<boolean> = new Observable((observer) => {
      this.isFavorite(id)
        .then((isFavorite) => {
          observer.next(isFavorite)
        })
        .catch((error) => {
          observer.error(error)
        })
    })
    return observable
  }
  async getFavorites() {
    if (this.verbose) {
      console.log(
        '%c[ UserPreferenceService : getFavorites() ]',
        'color: yellow'
      )
    }
    const { user } = this.localStorage.get('delphireUser')
    const segments = [user.id, 'favorites']
    const favoritesCollection = collection(
      this.firestore,
      'userPreferences',
      ...segments
    )
    const q = query(favoritesCollection)

    collectionSnapshots(favoritesCollection).subscribe(async (favorites) => {
      this.numFavorites = favorites.length
      const favoritesArr = []
      if (favorites.length === 0) {
        this.favorites$.next(of([]))
      }
      for (const favorite of favorites) {
        let favoriteWithResources = favorite.data()
        // console.log("favoriteWithResources : ",favoriteWithResources)
        favoriteWithResources.resource = await this.getFavoriteResources({
          id: favoriteWithResources.id,
          favType: favoriteWithResources.type
        })
        favoriteWithResources.name = favoriteWithResources.resource.name
        favoriteWithResources.isFavorite = true
        favoriteWithResources.resource.addedAt = favoriteWithResources.addedAt
        const data = favoriteWithResources as Favorite

        favoritesArr.push(data)
      }
      const unique = _.uniqBy(favoritesArr, 'id')
      const ordered = _.orderBy(unique, 'addedAt', ['desc'])
      this.favorites = ordered
      this.favorites$.next(of(ordered))
    })
  }

  async showDisclaimerModal() {
    const disclaimerRef = doc(this.firestore, 'options', 'disclaimer')
    const disclaimerDoc = await getDoc(disclaimerRef)
    const disclaimerData: Disclaimer = disclaimerDoc.data() as Disclaimer
    if (!disclaimerDoc.exists() || !disclaimerData.required) {
      return false
    }
    const dialogOptions = {
      title: '',
      message: disclaimerData.message,
      cancelText: '',
      confirmText: 'I Acknowledge',
      rememberChoice: false,
      id: 'disclaimer',
      panelClass: 'disclaimer-overlay',
      disableClose: true
    }

    const disclaimerChoice: boolean = this.localStorage.get('disclaimer')
    if (disclaimerChoice) {
      return true
    } else {
      this.dialogService.open(dialogOptions)

      return this.dialogService.confirmed().subscribe((confirmed) => {
        if (confirmed) {
          this.localStorage.set('disclaimer', true)
          return true
        } else {
          return false
        }
      })
    }
  }

  async removeFavorite(id: string) {
    const { user } = this.localStorage.get('delphireUser')
    const segments = [user.id, 'favorites', id]
    const favoriteDoc = doc(this.firestore, 'userPreferences', ...segments)
    const dialogOptions = {
      title: 'Remove Favorite?',
      message:
        'Are you sure you want to remove this as a favorite? It will no longer appear in your favorites list.',
      cancelText: 'CANCEL',
      confirmText: 'YES',
      rememberChoice: true,
      id: 'favorites',
      panelClass: 'favoritesDialog'
    }
    const favoritesChoice: boolean = this.localStorage.get('favorites')
    if (favoritesChoice) {
      deleteDoc(favoriteDoc)
    } else {
      this.dialogService.open(dialogOptions)

      this.dialogService.confirmed().subscribe((confirmed) => {
        if (confirmed) deleteDoc(favoriteDoc)
      })
    }
  }

  isBinary(type: string): boolean {
    switch (type) {
      case 'audio':
      case 'video':
      case 'document':
      case 'package':
        return true
      default:
        return false
    }
  }

  async setFavorites(item: any) {
    console.log('%c[ item ]', 'color: deeppink', item)
    if (!item.id) {
      if (this.isBinary(item.type)) {
        item.id = item.resourceId
      } else {
        item.id = item[item.type + 'Id']
      }
    }
    const isFavorite = await this.isFavorite(item.id)
    if (isFavorite) {
      await this.removeFavorite(item.id)
      return false
    }
    const { user } = this.localStorage.get('delphireUser')
    const segments = [user.id, 'favorites', item.id]
    const favoriteDoc = doc(this.firestore, 'userPreferences', ...segments)
    const docId = item.id
    const favorite: Favorite = {
      id: item.id,
      type: item.type.toLowerCase(),
      addedAt: moment().toISOString()
    }
    await setDoc(favoriteDoc, { ...favorite, docId })
    return true
  }
  constructor(
    private localStorage: LocalStorageService,
    private dialogService: ConfirmationDialogService
  ) {
    this.getFavorites()
  }
}
