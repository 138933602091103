import { t as Section } from '@delphire/fp'
import * as Visibility from './LandingPageSectionVisibility'

export type Type = Section.TypeOf<typeof codec>

export const name = 'LandingPageSection'

export const codecProgress = Section.strict(
  { type: Section.literal('progress') },
  `Progress${name}`
)

export const codecFavorites = Section.strict(
  { type: Section.literal('favorites') },
  `Favorites${name}`
)

export const codecSpaces = Section.strict(
  { type: Section.literal('spaces') },
  `Spaces${name}`
)

export const codecLibrary = Section.strict(
  {
    type: Section.literal('library'),
    tenantId: Section.string,
    libraryId: Section.string,
    visibility: Visibility.codecFromNullable
  },
  `Library${name}`
)

export const codec = Section.union(
  [codecProgress, codecFavorites, codecSpaces, codecLibrary],
  name
)

export const codecArray = Section.array(codec)

export const codecArrayFromNullable = Section.fromNullable(codecArray, [])
