import { t } from '@delphire/fp'
import * as LandingPageProperties from './LandingPageProperties'

export interface Type extends t.TypeOf<typeof codec> {}

export const name = 'LandingPageUpdateParams'

export const props = {
  id: t.string,
  properties: LandingPageProperties.codec
}

export const codec = t.strict(props, name)
