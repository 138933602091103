import { Injectable } from '@angular/core'
import { ResourceType, TrackingType } from './tracking.types'

@Injectable({
  providedIn: 'root'
})
export class TrackService {
  type: TrackingType = 'Analytics'

  resourceType!: string

  setType(type: TrackingType): void {
    this.type = type
  }

  setResourceType(type: ResourceType): void {
    this.resourceType = type
  }

  getType(): string {
    console.log(this.type)
    return this.type
  }

  getResourceType(): string {
    return this.resourceType
  }
  constructor() {}
}
