import { Clipboard } from '@angular/cdk/clipboard'
import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Clipboard as NativeClipBoard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { MdbModalRef } from 'mdb-angular-ui-kit/modal'
import PSPDFKit from 'pspdfkit'
import { environment } from '../../environments/environment'
import { Resource } from '../interfaces/resource'
import { DelphireApiService } from '../services/delphire-api.service'
import { DynamicLinkService } from '../services/dynamic-link.service'
import { ProgressService } from '../services/tracking/progress.service'
import { TrackService } from '../services/tracking/track.service'
import { TrackingService } from '../services/tracking/tracking.service'


@Component({
  selector: 'app-trackable-document-modal',
  templateUrl: './trackable-document-modal.component.html',
  styleUrls: ['./trackable-document-modal.component.sass'],
  providers: [{ provide: TrackingService, useClass: ProgressService }]
})
export class TrackableDocumentModalComponent implements OnInit {
  async generateLink() {
    const link = await this.dynamicLinkService.generateLink()
    if (Capacitor.isNativePlatform()) {
      await NativeClipBoard.write({ string: link })
    } else {
      this.clipboard.copy(link)
    }
    this.snackbar.open('Link Copied to ClipBoard!', 'Ok', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'linkSnackBar'
    })
  }

  resource!: Resource

  isCollapsed: boolean = false

  pageCount: number = 0

  close(): void {
    console.log('%c closing trackable document modal, unloading pspdfkit', 'color:yellow')
    PSPDFKit.unload('#pspdfkits')
    this.modalRef.close({ animate: false })
    // this.location.back()
  }
  constructor(
    public modalRef: MdbModalRef<TrackableDocumentModalComponent>,
    private delphireAPI: DelphireApiService,
    private trackService: TrackService,
    private trackingService: TrackingService,
    private location: Location,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard,
    private dynamicLinkService: DynamicLinkService
  ) {
    this.trackService.setResourceType('document')
  }

  trackPages(pageIndex: number): void {
    const pageNumber: number = pageIndex + 1
    const percentage: number = Math.floor((pageNumber / this.pageCount) * 100)
    let trackingParams

    //! YOU ARE HERE - ADD SELF TO PAYLOAD
    console.log('%c[ this.resource ]', 'background-color: red', this.resource)
    // return

    if (this.resource.completed || percentage > 90) {
      trackingParams = {
        namespace: 'DocumentProgress',
        type: 'DOCUMENT_PROGRESS',
        id: this.resource.id,
        originalKey: this.resource.id,
        payload: {
          currentPage: pageNumber,
          percentage: 100,
          completed: true
        }
      }
    } else {
      trackingParams = {
        namespace: 'DocumentProgress',
        type: 'DOCUMENT_PROGRESS',
        id: this.resource.id,
        originalKey: this.resource.id,
        payload: {
          currentPage: pageNumber,
          percentage: percentage,
          pageCount: this.pageCount,
          completed: false
        }
      }
    }

    this.trackingService.addEvent(trackingParams)
  }

  // ------------------------------------------------------------------------

  ngOnInit(): void {
    const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`
    this.delphireAPI
      .postRequest('resources/getDownloadUrl', {
        id: this.resource?.id,
        sourceKey: 'original'
      })
      .then((response) => {
        response.subscribe((url) => {
          PSPDFKit.load({
            baseUrl,
            container: '#pspdfkits',
            document: url.body.result.url,
            licenseKey: environment.pspdfkit.license,
            disableWebAssemblyStreaming: true
          })
            .then((instance) => {
              this.pageCount = instance.totalPageCount
              if (this.resource.payload?.currentPage) {
                const lastPage: number = this.resource.payload.currentPage - 1
                instance.setViewState((state) =>
                  state.set('currentPageIndex', lastPage)
                )
              }
              instance.addEventListener(
                'viewState.currentPageIndex.change',
                (pageIndex: number) => {
                  this.trackPages(pageIndex)
                }
              )
            })
            .catch((reason: any) => {
              console.log('%c pspdfkit ERROR', 'color:red')
              console.log(reason)
            })
        })
      })
  }
}
