<mat-toolbar id="delphire-nav-bar">

  <div fxLayoutAlign="start center">

    <button mat-button type="button" class="nav-button back-button" aria-label="Back" (click)="goBack()"
      *ngIf="!isHomePage">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd" />
        </svg>
        <span fxShow fxHide.xs class="back-text">Back</span>
      </div>
    </button>

    <div *ngIf="!isHomePage" class="nav-divider">
      <mat-divider [vertical]="true"></mat-divider>
    </div>

    <button mat-button type="button" class="nav-button home-button" aria-label="Home" [disabled]="isHomePage"
      (click)="goHome()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
      </svg>
    </button>

    <div class="nav-divider">
      <mat-divider [vertical]="true"></mat-divider>
    </div>

    <div *ngIf="space" class="space-logo">
      <img [src]="space.imageUrl | async" />
    </div>

  </div>

  <div fxFlex fxLayout="row" fxLayoutAlign="end center">

    <button fxHide fxShow.xs mat-button type="button" class="nav-button mobile-search-button" aria-label="Search"
      [disabled]="isHomePage" (click)="showMobileSearch()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
        style="width: 20px;  height: 20px;">
        <path fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd" />
      </svg>
    </button>

    <div fxHide fxShow.xs class="nav-divider">
      <mat-divider [vertical]="true"></mat-divider>
    </div>

    <div fxShow fxHide.xs class="search-container">
      <app-search fxFlex></app-search>
    </div>

  </div>

  <div fxLayoutAlign="end center">

    <button mat-button type="button" (click)="copyLink()" class="nav-button dynamic-link" *ngIf="checkSpace()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
          clip-rule="evenodd" />
      </svg>
    </button>

    <div *ngIf="checkSpace()" class="nav-divider">
      <mat-divider [vertical]="true"></mat-divider>
    </div>

    <button mat-button type="button" (click)="snav.toggle()" [matBadge]="notifications.length"
      [matBadgeHidden]="notifications.length === 0" matBadgeSize="small" matBadgeColor="warn"
      class="nav-button menu-button">
      <span fxShow fxHide.xs class="menu-text">Menu</span>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clip-rule="evenodd" />
      </svg>
    </button>

    <mat-sidenav-container (backdropClick)="snav.close()">
      <mat-sidenav #snav mode="over" fixedInViewport="true" fixedTopGap="48" fixedBottomGap="2" position="end">

        <mat-nav-list>

          <div mat-list-item>
            <span class="version">v {{ version }}</span>
            <div class="flyout-user-info layout-padding" flexLayout="row">
              <div fxFlex="33">
                <img *ngIf="user" [src]="user?.userInfo?.picture" referrerpolicy="no-referrer" class="grav" />
              </div>
              <div fxFlex flexLayout="row" flexLayoutAlign="start center">
                <h4>Hi, {{ user?.userInfo?.given_name }}</h4>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>


          <a class="myLearning" mat-list-item [routerLink]="['/spaces', getSpaceName(), 'my-learnings']" (click)="snav.close()">
            <div fxLayoutAlign="start center">
              <ng-icon name="HeroAcademicCapSolid" size="20px"></ng-icon>
              <span>My Learning</span>
            </div>
          </a>

          <mat-divider></mat-divider>

          <a mat-list-item [routerLink]="['/spaces', getSpaceName(), 'favorites']">
            <div fxLayoutAlign="start center">
              <ng-icon name="HeroStarSolid" size="20px"></ng-icon>
              <span>{{ language?.topNav?.favorites }}</span>
              <span class="badge" style="padding: 3px 6px">
                {{ userPreferenceService.numFavorites }}
              </span>
            </div>
          </a>

          <mat-divider *ngIf="getSpaceName() !== ''"></mat-divider>

          <a class="notifications" mat-list-item *ngIf="getSpaceName() !== ''" [routerLink]="['/spaces', getSpaceName(), 'notifications']"
            (click)="snav.close()">
            <div fxLayoutAlign="start center">
              <ng-icon name="HeroBellSolid" size="20px"></ng-icon>
              <span>Notifications</span>
              <span *ngIf="notifications.length > 0" class="badge" style="padding: 3px 6px">
                {{ notifications.length }}
              </span>
            </div>
          </a>

          <mat-divider></mat-divider>

          <a class="otherSpaces" mat-list-item [routerLink]="['/spaces']" (click)="snav.close()">
            <div fxLayoutAlign="start center">
              <ng-icon name="HeroGlobeAltSolid" size="20px"></ng-icon>
              <span>Other Spaces</span>
            </div>
          </a>

          <mat-divider></mat-divider>

          <a mat-list-item (click)="logout()">
            <div fxLayoutAlign="start center">
              <ng-icon name="HeroLogoutSolid" size="20px"></ng-icon>
              <span>{{ language.topNav.logOutNow }}</span>
            </div>
          </a>

        </mat-nav-list>

      </mat-sidenav>
    </mat-sidenav-container>

  </div>

  <div fxHide fxShow.xs class="mobile-search" [ngClass]="{ 'visible': mobileSearch }">
    <div fxFlex="22" fxFlex.xs="20"></div>
    <div fxFlex class="search-component-container">
      <app-search fxFlex></app-search>
    </div>
  </div>

</mat-toolbar>