import { t } from '@delphire/fp'
import { TenantEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'TaskList'

export const codecTaskIds = t.array(t.string)

export const codecTaskIdsFromNullable = t.fromNullable(codecTaskIds, [])

export const props = {
  ...TenantEntity.props,
  taskIds: codecTaskIdsFromNullable,
  iconResourceId: t.nullable(t.string)
}

export const codec = t.strict(props, name)
