import { t } from '@delphire/fp'
import { IAMEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Group'

export const props = { ...IAMEntity.props }

export const codec = t.strict(props, name)
