import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'

@Injectable({ providedIn: 'any' })
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  dialogRef!: MatDialogRef<ConfirmationDialogComponent>

  public open(options: any) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        rememberChoice: options.rememberChoice || undefined,
        id: options.id || undefined
      },
      panelClass: options.panelClass || undefined
    })
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res
      })
    )
  }
}
