import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit {
  openSupport(): void {}
  language: any = environment.appLanguage;
  isPillar: Boolean = false
  isEboni: Boolean = false
 
  constructor() {
    this.isPillar = environment.appName === "The Pillar Spot" ? true : false
    this.isEboni = environment.appName === "Eboni Spot" ? true : false
  }

  ngOnInit(): void {}
}
