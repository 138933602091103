import { t } from '@delphire/fp'
import { TenantEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Location'

export const props = {
  ...TenantEntity.props,
  address: t.nullable(t.string),
  iconResourceId: t.nullable(t.string),
  mapResourceId: t.nullable(t.string),
  mapUrl: t.nullable(t.string)
}

export const codec = t.type(props, name)
