import { t } from '@delphire/fp'

export type Type = t.TypeOfProps<typeof props>

export const props = makeProps(t.date)

export const propsFromISOString = makeProps(t.DateFromISOString)

export const propsFromNumber = makeProps(t.DateFromNumber)

function makeProps<T extends t.Any>(codec: T) {
  return { createdAt: codec, updatedAt: codec }
}
