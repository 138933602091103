import { t } from '@delphire/fp'
import { Timestamps } from '../data'

type Type = t.TypeOf<typeof codec>

const name = 'ResourceAssembly'

const props = {
  ...Timestamps.propsFromISOString,
  id: t.string,
  code: t.string,
  status: t.string,
  message: t.fromNullable(t.string, '')
}

const codec = t.strict(props, name)

export { Type, name, codec }
