import { t } from '@delphire/fp'
import { TenantEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Task'

export const props = {
  ...TenantEntity.props,
  dueAt: t.nullable(t.DateFromISOString),
  iconResourceId: t.nullable(t.string),
  taskListId: t.nullable(t.string)
}

export const codec = t.strict(props, name)
