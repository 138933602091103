import { t } from '@delphire/fp'
import { TenantEntity } from '../data'
import * as WidgetParameters from './WidgetParameters'
import * as WidgetPosition from './WidgetPosition'
import * as WidgetSize from './WidgetSize'
import * as WidgetStyle from './WidgetStyle'
import * as WidgetType from './WidgetType'

export type Type = t.TypeOf<typeof codec>

export const name = 'Widget'

export const props = {
  ...TenantEntity.props,
  type: t.nullable(WidgetType.codec),
  label: t.fromNullable(t.string, ''),
  disabled: t.fromNullable(t.boolean, false),
  iconResourceId: t.nullable(t.string),
  requiresNetworkConnection: t.fromNullable(t.boolean, false),
  size: WidgetSize.codec,
  style: WidgetStyle.codec,
  position: WidgetPosition.codec,
  parameters: WidgetParameters.codec
}

export const codec = t.strict(props, name)

export const codecRecord = t.record(t.string, codec)

export const codecRecordFromNullable = t.fromNullable(codecRecord, {})
