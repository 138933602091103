<div
  class="space-view"
  [ngStyle]="{
    'background-image': 'url(' + backgroundUrl + ')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'top left',
    'height': '100%',
    'padding': '5px 2px 105px 2px'
  }"
>
  <router-outlet
    (activate)="outletActivated()"
    (deactivate)="outletDeactivated()"
  ></router-outlet>
  <div class="spaceView" *ngIf="!backgroundUrl">
    <h2>Select A Space</h2>
    <mat-grid-list
      gutterSize="12"
      [cols]="gridCols"
      rowHeight="1:1"
      *ngIf="spaces.length > 0"
    >
      <mat-grid-tile
        (click)="selectSpace(space.id)"
        *ngFor="let space of spaces | orderBy: 'name':true"
      >
        <div id="space">
          <img mat-card-image [src]="space.imageUrl | async" />
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
