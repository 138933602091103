import { Inject, Injectable, Optional } from '@angular/core'
import { FirebaseApp, getApp } from '@angular/fire/app'
import { Database, equalTo, getDatabase, listVal, objectVal, orderByChild, query, ref } from '@angular/fire/database'
import { Observable } from 'rxjs'

export interface QueryFilter {
  name: string
  value: string
}
@Injectable()
export class FirebaseService {
  // Default database
  _firebaseDb = this.afdb
  constructor(
    private afdb: Database,
    @Optional() @Inject(String) dbName: string
  ) {
    if (dbName) {
      const app: FirebaseApp = getApp()
      this.afdb = getDatabase(app, dbName)
    }
  }
  db(dbName: string): FirebaseService {
    return new FirebaseService(this.afdb, dbName)
  }
  object(path: string): Observable<any> {
    const objRef = ref(this.afdb, path)
    return objectVal(objRef)
  }

  list(path: string, queryFn?: QueryFilter): Observable<any> {
    const listRef = ref(this.afdb, path)

    if (queryFn) {
      const queryRef = query(
        listRef,
        orderByChild(queryFn.name),
        equalTo(queryFn.value)
      )
      return listVal(queryRef)
    } else {
      return listVal(listRef)
    }
  }
}
