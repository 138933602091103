import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { DelphireApiService } from './delphire-api.service'
import { LocalStorageService } from './local-storage-service.service'

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  async getDownloadUrl(id: string) {
    const response = await this.delphireAPI.postRequest(
      'resources/getDownloadUrl',
      {
        id: id
      }
    )
    response.pipe(
      map((httpResponse) => {
        if (httpResponse.body.error) {
          return 'Resource Not Found'
        } else {
          return httpResponse.body.result.url
        }
      })
    )
    return response
  }
  constructor(
    private localStorage: LocalStorageService,
    private delphireAPI: DelphireApiService
  ) {}
}
