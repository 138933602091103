import { t } from '@delphire/fp'
import { Timestamps } from '../data'
import * as Properties from './LandingPageProperties'

export interface Type extends t.TypeOf<typeof codec> {}

export const name = 'LandingPage'

export const codec = t.strict(
  { ...Properties.props, ...Timestamps.propsFromISOString },
  name
)
