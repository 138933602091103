import { environment } from 'src/environments/environment'
import { FirebaseService } from '../services/firebase.service'
import { GroupService } from './delphire-group.service'
import { Injectable } from '@angular/core'
import { OrganizationService } from './delphire-organization.service'

interface User {
  email: string,
  employmentStartDate: Date,
  familyName: string,
  givenName: string,
  id: string,
  locale?: string,
  name: string,
  nickname?: string,
  picture?: string
  organizationId?: string
  groupId?: string | null
  subordinates?: any
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  verbose: boolean = false
  databaseRootUrl: string = `https://${environment.firebaseConfig.iamId}.firebaseio.com`
  raw: { [key: string]: any } = {}
  all: Array<User> = []
  lookup!: {
    byId: { [key: string]: User }
    byEmail: { [key: string]: User }
  }
  active = {} as User

  constructor(
    private fb: FirebaseService,
    private groups: GroupService,
    private organizations: OrganizationService
  ) {
    this.init()
  }

  init = async (): Promise<void> => {

    const dataPromises = Promise.all<Promise<object>>([
      this.getUserData()
    ])

    try {
      const value = await dataPromises;
      this.updateData(value[0]);
    } catch (error) {
      if (this.verbose) { console.error('[ UsersService - getUserData() - PROMISE REJECTED ]', error); }
    }
  }

  // ------------------------------------------------------------------------

  getUserData = (): Promise<object> => {

    return new Promise<object>((resolve, reject) => {
      this.fb
        .db(this.databaseRootUrl)
        .object(environment.firebasePaths.state + 'users')
        .subscribe((users) => {
          this.updateData(users);
          resolve(users);
        });
    })
  }

  // ------------------------------------------------------------------------

  resetData(): void {
    this.all = []
    this.lookup = {
      byId: {},
      byEmail: {}
    }
    this.active = {} as User
  }
  // ------------------------------------------------------------------------

  updateData(newData: any): void {
    this.resetData()
    this.raw = newData;

    for(const userId in this.raw["byId"]) {
      this.addUserInfo(userId);
      this.addUserGroups(userId);
      this.addUserOrganizations(userId);
      // this.getSubordinates(userId);
      this.getDescendants(userId);

      const fullUser = this.raw["byId"][userId] as User
      this.all.push(fullUser)
      this.lookup.byId[fullUser.id] = fullUser
      this.lookup.byEmail[fullUser.email] = fullUser
    }

    for(const userId in this.raw["byId"]) {
      this.getSubordinates(userId);
    }

  }

  // ------------------------------------------------------------------------

  addUserInfo(userId: string): void {

    const fullUser = this.raw["byId"][userId] as User
    const thisUserDetails = this.raw["userInfo"][userId]

    // add details from userInfo node if the user has details
    if(thisUserDetails) {
      if(thisUserDetails.picture) fullUser.picture = thisUserDetails.picture
      if(thisUserDetails.nickname) fullUser.nickname = thisUserDetails.nickname
      if(thisUserDetails.locale) fullUser.locale = thisUserDetails.locale
    }

    // give them a full name FROM byId
    fullUser.name = fullUser.givenName + ' ' + fullUser.familyName
  }

  // ------------------------------------------------------------------------

  addUserGroups(userId: string): void {
    // if(this.verbose) { console.log('%c[ addUserGroups() : groups ]', 'color: yellow', this.groups) }
    const fullUser = this.raw["byId"][userId] as User
    const userGroupData = this.groups.userGroupData[userId]
    if(userGroupData) {
      const userGroupId = Object.keys(userGroupData)[0]
      fullUser.groupId = userGroupId
    } else {
      fullUser.groupId = null
    }
  }

  // ------------------------------------------------------------------------

  addUserOrganizations(userId: string): void {
    // if(this.verbose) { console.log('%c[ addUserOrganizations() : organizations ]', 'color: yellow', this.organizations) }
    const fullUser = this.raw["byId"][userId] as User
    const userOrgData = this.organizations.memberOrgData[userId]
    if(userOrgData) {
      const userOrgId = Object.keys(userOrgData)[0]
      fullUser.organizationId = userOrgId
    } else {
      fullUser.groupId = null
    }
  }

  // ------------------------------------------------------------------------

  public getSubordinates = (userId: string): void => {
    if(!this.organizations.managerOrgData) return
    // if(this.verbose) { console.log('%c[ getSubordinates() ]', 'color: yellow') }
    const fullUser = this.raw["byId"][userId] as User
    const managerOrgData = this.organizations.managerOrgData[userId]
    if(managerOrgData) {
      const managerOrgId = Object.keys(managerOrgData)[0]
      // if(this.verbose) { console.log('%c[ "USER IS A MANAGER OF" ]', 'color: teal', managerOrgId) }
      fullUser.subordinates = []
      for(const subordinateId in this.organizations.orgMemberData[managerOrgId]) {
        fullUser.subordinates.push(this.lookup.byId[subordinateId])
      }
    }
  }

  // ------------------------------------------------------------------------

  setActiveUser(value: string, type: string): void {
    // if(this.verbose) { console.log('%c[ setActiveUser() ]', 'color: yellow') }
    switch (type) {
      case 'id':
        this.active = this.lookup.byId[value]
        break;
      case 'email':
        this.active = this.lookup.byEmail[value]
        break;

      default:
        break;
    }
  }

  // ------------------------------------------------------------------------

  getDescendants(userId: string): void {
    // if(this.verbose) { console.log('%c[ getDescendants() ]', 'color: yellow') }
  }

}
