import { Injectable } from '@angular/core'
import { collection, CollectionReference, doc, Firestore, getDoc } from '@angular/fire/firestore'
import { LandingPage, LandingPageSection } from '@delphire/dto'
import { Either, pipe, t } from '@delphire/fp'

export interface Entity {
  readonly id: string
}

@Injectable({ providedIn: 'root' })
export class LandingPageService {
  readonly landingPagesCol: CollectionReference
  readonly tenantLandingPagesCol: CollectionReference
  constructor(readonly firestore: Firestore) {
    this.landingPagesCol = collection(firestore, 'landingPages')
    this.tenantLandingPagesCol = collection(firestore, 'tenantLandingPages')
  }

  async getTenantLandingPageId({ tenant }: { tenant: Entity }) {
    const d = doc(this.tenantLandingPagesCol, tenant.id)
    const s = await getDoc(d)
    const v = s.data()?.landingPageId
    return typeof v === 'string' ? v : null
  }

  async read(id: string): Promise<LandingPage.Type | null> {
    const landingPageDoc = doc(this.landingPagesCol, id)
    const landingPageSnap = await getDoc(landingPageDoc)
    return pipe(
      landingPageSnap.data(),
      LandingPage.codec.decode,
      Either.mapLeft(t.PathReporter.failure),
      Either.fold(
        (errors) => {
          console.error(`Could not decode landing page`)
          return null
        },
        (result) => {
          return result
        }
      )
    )
  }

  async getUserLandingPage({
    user,
    group,
    tenant
  }: {
    user: Entity
    group: Entity
    tenant: Entity
  }): Promise<LandingPage.Type | null> {
    const id = await this.getTenantLandingPageId({ tenant })

    if (typeof id !== 'string') return null

    const landingPage = await this.read(id)

    if (landingPage == null) return null

    const sectionsFilter = sectionVisibilityFilter({ user, group })
    const sections = landingPage.sections.filter(sectionsFilter)

    return { ...landingPage, sections }
  }
}

function sectionVisibilityFilter({
  user,
  group
}: {
  user: Entity
  group: Entity
}) {
  return (section: LandingPageSection.Type): boolean => {
    if (section.type !== 'library') {
      return true
    }

    const { visibility: v } = section

    switch (v.default) {
      case 'allow': {
        return !(v.groups[group.id] === false || v.users[user.id] === false)
      }
      case 'deny': {
        const groupAccess = section.visibility.groups[group.id]
        const userAccess = section.visibility.users[user.id]
        return (
          (groupAccess === true && userAccess !== false) ||
          (userAccess === true && groupAccess !== false)
        )
      }
    }
  }
}
