import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'
import 'moment-duration-format'

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number): any {
    return moment.duration(value, 'seconds').format({
      precision: 0,
      template: 'y [years], w [weeks], d [days], h [hr], m [min]'
    })
  }
}
