import { Injectable } from '@angular/core'
import { Observable, of, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorage: Storage

  changes$ = new Subject()

  constructor() {
    this.localStorage = window.localStorage
  }

  getItem(key: string): Observable<any> {
    if (this.isLocalStorageSupported) {
      const value: string | null = this.localStorage.getItem(key)
      if (value) {
        return of(JSON.parse(value))
      } else {
        return of(null)
      }
    } else {
      return of(null)
    }
  }
  get(key: string): any {
    if (this.isLocalStorageSupported) {
      const value: string | null = this.localStorage.getItem(key)
      if (value === 'undefined') {
        this.localStorage.clear()
        window.location.reload()
      }
      if (value) {
        return JSON.parse(value)
      } else {
        return null
      }
    }

    return null
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value))
      this.changes$.next({
        type: 'set',
        key,
        value
      })
      return true
    }

    return false
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key)
      this.changes$.next({
        type: 'remove',
        key
      })
      return true
    }

    return false
  }

  keys(namespace: string): string[] {
    const keys: string[] = []
    for (let i = 0; i < this.localStorage.length; i++) {
      const key = this.localStorage.key(i)
      if (!namespace || key?.indexOf(namespace) === 0) {
        if (key) keys.push(key)
      }
    }
    return keys
  }

  clear(): void {
    this.localStorage.clear()
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }
}
