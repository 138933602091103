import { Injectable } from '@angular/core'
import { Resource } from '../interfaces/resource'
import { ProgressService } from './tracking/progress.service'
import { TrackService } from './tracking/track.service'


@Injectable()
export class PdfTrackingService {
  resource!: Resource

  pageCount: number = 0

  public setResource(resource: Resource): void {
    this.resource = resource
  }

  public setPageCount(pageCount: number): void {
    this.pageCount = pageCount
  }
  public trackPages(pageIndex: number): void {
    const pageNumber: number = pageIndex + 1
    const percentage: number = Math.floor((pageNumber / this.pageCount) * 100)
    let trackingParams

    //! THIS ONLY SEEMS TO RUN ON IOS
    console.log('%c[ this.resource ]', 'background-color: lime', this.resource)
    // return

    if (this.resource.completed || percentage > 90) {
      trackingParams = {
        namespace: 'DocumentProgress',
        type: 'DOCUMENT_PROGRESS',
        id: this.resource.id,
        originalKey: this.resource.id,
        payload: {
          currentPage: pageNumber,
          percentage: 100,
          completed: true
        }
      }
    } else {
      trackingParams = {
        namespace: 'DocumentProgress',
        type: 'DOCUMENT_PROGRESS',
        id: this.resource.id,
        originalKey: this.resource.id,
        payload: {
          currentPage: pageNumber,
          percentage: percentage,
          pageCount: this.pageCount,
          completed: false
        }
      }
    }
    this.trackingService.addEvent(trackingParams)
  }
  constructor(
    private trackingService: ProgressService,
    private trackService: TrackService
  ) {
    this.trackService.setResourceType('document')
  }
}
