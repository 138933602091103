
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'quiz-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.sass']
})
export class QuestionComponent implements OnInit {
  position: number = 0
  answered: boolean = false
  correct: boolean = false
  selected: Array<number> = []
  dev: boolean = environment.production ? false : true

  @Input() quiz: any
  @Input() questions: any

  @Output('closeQuiz') closeQuiz: EventEmitter<any> = new EventEmitter()
  @Output('completeQuiz') completeQuiz: EventEmitter<any> = new EventEmitter()
  @Output('updateUserData') updateUserData: EventEmitter<any> = new EventEmitter()
  @Output('trackQuizEvent') trackQuizEvent: EventEmitter<any> = new EventEmitter()

  constructor() {}

  quit(): void {
    this.closeQuiz.emit()
  }

  resetQuiz(): void {
    console.log('RESET QUIZ')

    this.position = 0
    this.answered = false
    this.correct = false
    this.selected = []
  }

  continue() {
    if (this.position + 1 >= Number(this.quiz.questionCount)) {
      this.completeQuiz.emit()
      this.resetQuiz()
    } else {
      this.answered = false
      this.selected = []
      this.correct = false
      this.position++
    }
  }

  submit(): void {
    if (this.answered) {
      this.continue()
    } else {
      this.answered = true
      this.correct = this.isCorrect()

      const q = this.questions[this.position]

      const questionData = {
        question: q,
        selectedAnswer: this.selected,
        answeredCorrect: this.correct,
        answered: this.answered,
        correctAnswer: q.correct
      }

      this.updateUserData.emit(questionData)

      const question = {
        id: q.id,
        text: q.text,
        answers: q.answers
      }

      this.trackQuizEvent.emit({ type: 'QUIZ_ANSWER', question: question })
    }
  }

  isCorrect(): boolean {
    if (this.selected.length == this.questions[this.position].correct.length) {
      for (const s of this.selected) {
        if (this.questions[this.position].correct.includes(s)) {
          return true
        }
      }
    }
    return false
  }

  isSelected(index: number): boolean {
    if (this.selected.includes(index)) {
      return true
    }
    return false
  }

  answerIsCorrect(index: number): boolean {
    return this.questions[this.position].correct.includes(index)
  }

  toggleAnswer(index: number): void {
    if (!this.answered) {
      if (this.selected.includes(index)) {
        const pos = this.selected.indexOf(index)
        this.selected.splice(pos, 1)
      } else {
        this.selected.push(index)
      }
    }
  }

  ngOnInit(): void {}
}
