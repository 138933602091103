import { t } from '@delphire/fp'

type Type = t.TypeOf<typeof codec>

const name = 'ResourceSource'

const props = {
  name: t.string,
  url: t.string,
  type: t.nullable(t.string),
  meta: t.unknown,
  size: t.nullable(t.number),
  mime: t.nullable(t.string),
  httpsUrl: t.nullable(t.string),
  contentType: t.nullable(t.string),
  metadata: t.unknown
}

const codec = t.type(props, name)

const codecRecord = t.record(t.string, codec)

const codecRecordFromNullable = t.fromNullable(codecRecord, {})

export { Type, name, codec, codecRecordFromNullable }
