import { Component, OnInit } from '@angular/core'
import { getApp } from '@angular/fire/app'
import {
  collection,
  collectionSnapshots,
  deleteDoc,
  doc,
  getFirestore,
  query,
  updateDoc,
  where
} from '@angular/fire/firestore'
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage'
import { ActivatedRoute } from '@angular/router'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import _ from 'lodash'
import { from, Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { Resource } from '../interfaces/resource'
import { Space } from '../interfaces/space'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent implements OnInit {
  notifications: any[] = []

  space!: Space

  async deleteNotification(notification: any) {
    const app = getApp()
    const firestore = getFirestore(app)
    const docRef = doc(firestore, 'notifications', notification.id)
    await deleteDoc(docRef)
  }
  async handleOpenLink(
    itemId: string,
    id: string,
    type: string,
    tenantId: string
  ): Promise<void> {
    const docRef = doc(getFirestore(), 'notifications', id)
    await updateDoc(docRef, { read: true })
    switch (type) {
      case 'Resource':
        this.fb
          .db(this.space.databaseUrl)
          .object(environment.firebasePaths.space + 'resources/' + itemId)
          .subscribe((data) => {
            const resource: Resource = data as Resource
            this.linkService.handleLink({
              type: resource.type,
              resource: resource,
              route: this.route
            })
          })
        break
      case 'None':
        break
      default:
        this.linkService.handleLink({
          type: type.toLowerCase(),
          id: itemId,
          route: this.route
        })
        break
    }
  }
  constructor(
    private linkService: DelphireLinkService,
    private fb: FirebaseService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute
  ) {}
  getSpaceImage(id: string): Observable<string> {
    const { tenants } = this.localStorage.get('delphireUser')
    const { name } = _.find(tenants, (t) => {
      return t.id === id
    })
    const refUrl: string = 'Logos/' + name + '.png'
    const app = getApp()
    const storage = getStorage(app)
    const imgRef = ref(storage, refUrl)
    return from(getDownloadURL(imgRef))
  }
  ngOnInit(): void {
    const { user } = this.localStorage.get('delphireUser')
    this.space = this.localStorage.get('currentSpace')
    let device: string = 'web'
    if (Capacitor.isNativePlatform()) {
      device = 'ios'
      PushNotifications.removeAllDeliveredNotifications()
    } else {
      device = 'web'
    }

    const app = getApp()
    const firestore = getFirestore(app)
    const notificationsRef = collection(firestore, 'notifications')
    const q = query(
      notificationsRef,
      where('uid', '==', user.id),
      where('device', '==', device)
    )
    collectionSnapshots(q).subscribe((notifications) => {
      this.notifications = []
      notifications.forEach((note) => {
        const data = note.data()
        const id = note.id
        //const spaceImage = this.getSpaceImage(data.tenantId)
        this.notifications.push({ ...data, id })
      })
    })
  }
}
