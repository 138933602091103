import { t } from '@delphire/fp'
import { TenantEntity } from '../data'
import { Widget } from '../widget'
import * as LayoutGrid from './LayoutGrid'
import * as LayoutLayouts from './LayoutLayouts'
import * as LayoutSize from './LayoutSize'
import * as LayoutStyle from './LayoutStyle'

export type Type = t.TypeOf<typeof codec>

export const name = 'Layout'

export const props = {
  ...TenantEntity.props,
  grid: LayoutGrid.codec,
  size: LayoutSize.codec,
  style: LayoutStyle.codecFromNullable,
  layouts: LayoutLayouts.codecFromNullable,
  widgets: Widget.codecRecordFromNullable,
  publishedAt: t.nullable(t.DateFromISOString),
  backgroundResourceId: t.nullable(t.string)
}

export const codec = t.strict(props, name)
