<mdb-carousel
  [controls]="true"
  [indicators]="true"
  [pause]="true"
  *ngIf="slides.length > 0"
>
  <mdb-carousel-item (click)="openItem(slide)" *ngFor="let slide of slides">
    <img [src]="slide.url" class="d-block w-100" alt="..." />
  </mdb-carousel-item>
</mdb-carousel>
