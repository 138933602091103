import { pipe } from 'fp-ts/lib/function'
import { ApplyPar, fold, TaskEither } from 'fp-ts/lib/TaskEither'
import { sequenceS } from './Apply'

export * from 'fp-ts/lib/TaskEither'
export { TaskEither as Type } from 'fp-ts/lib/TaskEither'

/**
 *
 * @param task
 * @returns An unsafe promise that will reject with the left value
 */
export function toUnsafePromise<L, A>(task: TaskEither<L, A>) {
  return pipe(
    task,
    fold(
      (error) => () => Promise.reject(error),
      (a) => () => Promise.resolve(a)
    )
  )()
}

export const sequenceSPar = sequenceS(ApplyPar)
