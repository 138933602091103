import {
  ChangeDetectionStrategy,
  Component, Inject
} from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LocalStorageService } from '../services/local-storage-service.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.sass']
})
export class ConfirmationDialogComponent {
  preferences: FormGroup = new FormGroup({
    dontShowAgain: new FormControl(false)
  })
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cancelText: string
      confirmText: string
      message: string
      title: string
      hideConfirm: boolean
      rememberChoice: boolean | undefined
      id: string | undefined
    },

    private mdDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private localStorage: LocalStorageService
  ) {}

  public cancel() {
    this.close(false)
  }

  public close(value: any) {
    this.mdDialogRef.close(value)
  }

  public confirm() {
    if (this.preferences.get('dontShowAgain')?.value && this.data.id) {
      this.localStorage.set(this.data.id, true)
    }
    this.close(true)
  }

  // @HostListener('keydown.esc')
  // public onEsc() {
  //   this.close(false)
  // }
}
