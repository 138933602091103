import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'LayoutStyle'

export const props = {
  backgroundColor: t.nullable(t.string),
  backgroundResourceId: t.nullable(t.string)
}

export const codec = t.strict(props, name)

export const empty: Type = {
  backgroundColor: null,
  backgroundResourceId: null
}

export const codecFromNullable = t.fromNullable(codec, empty)
