import { environment } from 'src/environments/environment'
import { FirebaseService } from './firebase.service'
import { Injectable } from '@angular/core'
import { LocalStorageService } from './local-storage-service.service'

interface Org {
  id: string,
  name: string
}

@Injectable({
  providedIn: 'root'
})

export class OrganizationService {

  verbose: boolean = false
  databaseRootUrl: string = `https://${environment.firebaseConfig.iamId}.firebaseio.com`
  organizationData: { [key: string]: any } = {}
  memberOrgData: { [key: string]: any } = {}
  orgMemberData: { [key: string]: any } = {}
  managerOrgData: { [key: string]: any } = {}
  all: Array<Org> = []
  lookup: { [key: string]: Org } = {}

  constructor(
    private fb: FirebaseService,
    private localStorage: LocalStorageService
  ) {
    this.init()
  }

  init = async (): Promise<void> => {

    const dataPromises = Promise.all<Promise<object>>([
      this.getOrgData(),
      this.getMemberOrgData(),
      this.getOrgMemberData(),
      this.getManagerOrgData()
    ])

    try {
      const value = await dataPromises;
      this.organizationData = value[0];
      this.memberOrgData = value[1];
      this.orgMemberData = value[2];
      this.managerOrgData = value[3];
    } catch (error) {
      if (this.verbose) { console.error('[ OrganizationService - getOrgData() - PROMISE REJECTED ]', error); }
    }
  }

  // ------------------------------------------------------------------------

  getOrgData = (): Promise<object> => {

    return new Promise<object>((resolve, reject) => {
      this.fb
        .db(this.databaseRootUrl)
        .object(environment.firebasePaths.state + 'organizations/byId')
        .subscribe((organizations) => {
          resolve(organizations);
        });
    })
  }

  // ------------------------------------------------------------------------

  getMemberOrgData = (): Promise<object> => {

    return new Promise<object>((resolve, reject) => {
      this.fb
        .db(this.databaseRootUrl)
        .object(environment.firebasePaths.state + 'organizationMemberAssociation/memberOrganizations')
        .subscribe((memberOrganizationsAssociation) => {
          // if(this.verbose) { console.log('%c[ memberOrganizationsAssociation ]', 'background-color: whitesmoke; color: black', memberOrganizationsAssociation) }
          resolve(memberOrganizationsAssociation);
        });
    })
  }

  // ------------------------------------------------------------------------

  getOrgMemberData = (): Promise<object> => {

    return new Promise<object>((resolve, reject) => {
      this.fb
        .db(this.databaseRootUrl)
        .object(environment.firebasePaths.state + 'organizationMemberAssociation/organizationMembers')
        .subscribe((organizationMemberAssociation) => {
          resolve(organizationMemberAssociation);
        });
    })
  }

  // ------------------------------------------------------------------------

  getManagerOrgData = (): Promise<object> => {

    return new Promise<object>((resolve, reject) => {
      this.fb
        .db(this.databaseRootUrl)
        .object(environment.firebasePaths.state + 'managerOrganizationAssociation/managerOrganizations')
        .subscribe((managerOrganizationAssociation) => {
          resolve(managerOrganizationAssociation);
        });
    })
  }

}
