import { t } from '@delphire/fp'
import { ACL } from '../acl'
import { TenantEntity } from '../data'
import * as ResourceAssembly from './ResourceAssembly'
import * as ResourceSource from './ResourceSource'
import * as ResourceType from './ResourceType'
import * as ResourceUpload from './ResourceUpload'

export type Type = t.TypeOf<typeof codec>

export const name = 'Resource'

export const props = {
  ...TenantEntity.props,
  key: t.nullable(t.string),
  type: ResourceType.codec,
  assembly: t.nullable(ResourceAssembly.codec),
  upload: t.nullable(ResourceUpload.codec),
  sources: ResourceSource.codecRecordFromNullable,
  exclusiveReaders: ACL.codecFromNullable,
  tags: t.fromNullable(t.array(t.string), [])
}

export const codec = t.strict(props, name)
