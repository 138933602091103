import { t } from '@delphire/fp'
import { TenantEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Modal'

export const props = {
  ...TenantEntity.props,
  content: t.fromNullable(t.string, '')
}

export const codec = t.strict(props, name)
