<style>
  @media (max-width: 1200px) {
    .my-learnings.wrapper {
      padding: 0 1em;
    }
  }

  @media (max-width: 600px) {
    .my-learnings .header-table .header {
      flex-direction: column;
    }
  }
  @media (max-width: 1023px) {
    .table-container {
      flex-direction: column;
    }

    .my-learnings .header-table .table-container md-content:last-child {
      margin-left: 0;
      margin-bottom: 16px;
    }
  }

  .my-learnings .header-table .resource-name {
    word-break: break-word;
  }

  .my-learnings .header-table .fa-info-circle {
    color: #333333;
    font-size: 18px;
    margin-left: 5px;
    margin-top: 2px;
  }

  .my-learnings .header-table .spacer {
    flex: 1;
  }

  .table-container md-table-container {
    display: flex;
    flex-direction: column;
  }

  .table-container md-table-container .md-table {
    flex: 1;
  }

  /* -------------------------------------------------------- */

  .user-division-select {
    padding-bottom: 0;
    position: relative;
  }

  .my-learnings-header-row {
    position: relative;
  }

  .my-learnings-user-picker {
    position: absolute;
    top: 15px;
    left: 280px;
    font-size: 12px;
    background: #fff;
    padding: 0 20px;
    color: #000;
  }

  /* -------------------------------------------------------- */

  .my-learnings.wrapper {
    margin: 0;
    padding: 0;
    top: 0;
  }

  .my-learnings .header-table {
    margin: 0;
    padding: 0;
  }

  /* ------------------------------------------------ */

  .my-learnings .header-table .header {
    width: 100%;
    border-radius: 0;
    margin: 0;
    padding: 12px 20px !important;
  }

  .my-learnings .header-table .header .header-copy {
    text-align: left;
    font-weight: 100;
    font-size: 44px;
  }
  .my-learnings .header-table .header .header-copy div {
    position: relative;
    top: -4px;
  }

  .my-learnings .header-table .header .header-logo {
    text-align: right;
  }

  .my-learnings .header-table .header .header-logo img {
    width: 250px;
    float: right;
    display: block;
  }

  /* ------------------------------------------------ */

  .my-learnings .header-table .my-learnings-header {
    background: #f7f7f7;
    padding: 10px 20px;
    border-bottom: 2px solid rgba(25, 86, 149, 0.5);
  }

  /* --------------------- */

  .my-learnings .md-button {
    text-transform: none;
    font-size: 20px;
  }

  .my-learnings .md-button.md-default-theme.md-accent,
  .my-learnings .md-button.md-accent {
    color: #000;
  }
  .my-learnings
    md-nav-bar.md-default-theme
    .md-button._md-nav-button.md-unselected,
  .my-learnings md-nav-bar .md-button._md-nav-button.md-unselected {
    color: #000;
  }
  .my-learnings md-nav-bar md-nav-ink-bar {
    color: #195695;
    background-color: #195695;
  }

  /* --------------------- */

  .my-learnings .header-table .my-learnings-search {
    padding: 20px 0 10px 0;
  }

  .my-learnings .header-table .my-learnings-search input {
    width: 100%;
    background: #e2e2e2;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    padding: 12px 20px;
  }

  /* --------------------- */

  .my-learnings .header-table .my-learnings-subordinates {
    overflow: hidden;
    font-family: roboto, arial, sans-serif;
    padding-top: 8px;
  }

  .my-learnings
    .header-table
    .my-learnings-subordinates
    .my-learnings-subordinate {
    float: left;
    border-radius: 20px;
    background: #fff;
    font-size: 16px;
    margin: 6px 12px 6px 0;
    padding: 6px 10px;
    border: 1px solid #d5d5d5;
    color: #000;
  }
  .my-learnings
    .header-table
    .my-learnings-subordinates
    .my-learnings-subordinate.active {
    background: #195695;
    color: #fff;
    font-weight: bold;
  }

  /* ------------------------------------------------ */

  .my-learnings .my-learnings-body {
    padding: 20px;
  }
  .my-learnings .my-learnings-body .my-learnings-body-wrapper {
    max-width: 1440px;
    margin: 0 auto;
  }

  .my-learnings
    .my-learnings-body
    .my-learnings-body-wrapper
    .my-learnings-body-header {
    font-size: 14px;
    color: #000;
  }

  .my-learnings
    .my-learnings-body
    .my-learnings-body-wrapper
    .my-learnings-body-header
    .center {
    text-align: center;
  }

  .my-learnings
    .my-learnings-body
    .my-learnings-body-wrapper
    .my-learnings-body-header
    .right {
    text-align: right;
  }

  .my-learnings
    .my-learnings-body
    .my-learnings-body-wrapper
    .my-learnings-body-name {
    font-size: 45px;
    color: #000;
  }
  .my-learnings
    .my-learnings-body
    .my-learnings-body-wrapper
    .my-learnings-body-name
    i {
    background: #f7f7f7;
    padding: 14px;
    border-radius: 50px;
    margin: 0 20px;
  }
</style>
<div class="my-learnings wrapper">
  <div class="header-table">
    <!-- ============================================================================================= -->

    <div
      class="header my-learnings-header-row"
      flexLayout="row"
      flexLayoutAlign="center center"
    >
      <div fxFlex="50" class="header-copy">My Learning</div>
      <div
        fxFlex="50"
        class="header-logo"
        *ngIf="imageUrl"
        [hidden]="mobileBrowser() && delphireSearchActive()"
      >
        <img src="brand/{{ brandName }}/assets/images/brand-logo.png" />
      </div>
    </div>
    <!-- ============================================================================================= -->

    <div class="my-learnings-header" *ngIf="myDescendantsArray.length != 0">
      <mat-tab-group>
        <mat-tab label="My Progress"></mat-tab>
        <mat-tab label="My Team's Progress">
          <!-- <div class="fxLayout-row fxLayout-padding user-division-select">
                <div class="division-select">
                  <md-input-container>
                    <label>Division</label>
                    <md-select ng-model="$root.selectedDivision" md-on-close="clearDivisionSearchTerm()" md-container-class="select-container" ng-change="clearUser()">
                      <md-optgroup label="divisions">
                        <md-option ng-value="division.id" ng-repeat="division in myOrganizationsArray | filter:divisionSearchTerm">
                          {{division.name}}
                        </md-option>
                      </md-optgroup>
                    </md-select>
                  </md-input-container>
                </div>
      
                <div class="user-select" *ngIf="$root.selectedDivision">
                  <md-input-container>
                    <label>User</label>
                    <md-select ng-model="$root.myLearningSelectedUser" md-on-close="clearUserSearchTerm()" md-container-class="select-container" ng-change="chooseDescendant()">
                      <md-optgroup label="users">
                        <md-option ng-value="user.id" ng-repeat="user in myDescendantsArrays[$root.selectedDivision] | filter:UserSearchTerm">
                          {{user.email}}
                        </md-option>
                      </md-optgroup>
                    </md-select>
                  </md-input-container>
                </div>
              </div> -->
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- ============================================================================================= -->
    <div class="my-learnings-body">
      <div class="my-learnings-body-wrapper">
        <div
          class="my-learnings-body-mine"
          *ngIf="myLearningActiveSection == 'progress-mine'"
        >
          <div class="table-container">
            <div
              fxLayout="row"
              fxFlex="100"
              class="md-padding"
              [hidden]="myLearningsInProgress.length == 0"
            >
              <!-- ============================================ IN PROGRESS TABLE ================================================= -->
              <div fxLayout="column" class="md-padding md-content">
                <div class="table-title">
                  <span>Items in Progress</span>
                  <i
                    class="fa fa-info-circle"
                    [mdePopoverTriggerFor]="infoPopover"
                    mdePopoverTriggerOn="click"
                    #popoverTrigger="mdePopoverTrigger"
                  ></i>
                  <mde-popover
                    #infoPopover="mdePopover"
                    [mdePopoverOverlapTrigger]="false"
                    class="infoPopover"
                    style="margin-left: -4px"
                  >
                    <mat-card style="max-width: 300px">
                      <mat-card-content>
                        Click on any item to Open
                      </mat-card-content>
                    </mat-card>
                  </mde-popover>

                  <div class="spacer"></div>
                  <mat-form-field>
                    <mat-select
                      #selectInProgress
                      [(value)]="selectedSorterIncomplete"
                      class="md-select"
                    >
                      <mat-option
                        *ngFor="let sorter of sortOptionsIncomplete"
                        [value]="sorter"
                        >{{ sorter }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <mat-table
                  *ngIf="myLearningsInProgress.length > 0"
                  [dataSource]="dataSource"
                  class="md-table"
                >
                  <ng-container matColumnDef="name">
                    <mat-header-cell col-span="2" *matHeaderCellDef
                      ><span>Items in Progress</span></mat-header-cell
                    >
                    <mat-cell *matCellDef="let source" class="learning-row">
                      <button
                        (click)="toggleFavorite(source)"
                        *ngIf="!isFavorite(source)"
                      >
                        <i class="fa fa-star-o"></i>
                      </button>
                      <button
                        (click)="toggleFavorite(source)"
                        *ngIf="isFavorite(source)"
                      >
                        <i class="fa fa-star"></i>
                      </button>
                      <div
                        fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="start center"
                        (click)="openItem(source)"
                      >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'audio'"
                          >audiotrack</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'document'"
                          >picture_as_pdf</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'video'"
                          >ondemand_video</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'module'"
                          >tv</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'package'"
                          >snippet_folder</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'Quiz'"
                          >psychology</i
                        >
                        <span class="resource-name"
                          >{{ source.name }}
                          <span
                            style="
                              font-size: x-small;
                              font-weight: bold;
                              font-style: italic;
                              display: block;
                            "
                          >
                            ({{ getSpaceName(source.tenantId) }})
                          </span></span
                        >
                        <div
                          class="learning-item-progress"
                          *ngIf="source.type == 'Quiz'"
                        >
                          <p class="percent">Incomplete</p>
                        </div>
                        <div
                          class="learning-item-progress"
                          *ngIf="
                            source.type != 'Quiz' && source.percentage != 0
                          "
                        >
                          <p class="percent">
                            {{
                              source.percentage ||
                                source.precentage ||
                                source.modulePercentage ||
                                source.mediaPercentage ||
                                source.mediaPrecentage ||
                                source.documentPercentage ||
                                1
                            }}% complete
                          </p>
                          <mat-progress-bar
                            mode="determinate"
                            value="{{
                              source.percentage ||
                                source.precentage ||
                                source.modulePercentage ||
                                source.mediaPercentage ||
                                source.documentPercentage ||
                                1
                            }}"
                          ></mat-progress-bar>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="sortersIncomplete"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: sortersIncomplete"
                  ></mat-row>
                </mat-table>
                <mat-paginator
                  #inProgressPaginator
                  fxFlex="50"
                  [pageSize]="10"
                  [length]="myLearningsInProgress.length"
                >
                </mat-paginator>
              </div>
              <!-- ============================================ COMPLETED TABLE ================================================= -->
              <div fxLayout="column" class="md-padding md-content">
                <div class="table-title">
                  <span>Completed Items</span>
                  <i
                    class="fa fa-info-circle"
                    [mdePopoverTriggerFor]="infoPopover"
                    mdePopoverTriggerOn="click"
                    #popoverTrigger="mdePopoverTrigger"
                  ></i>
                  <mde-popover
                    #infoPopover="mdePopover"
                    [mdePopoverOverlapTrigger]="false"
                    class="infoPopover"
                    style="margin-left: -4px"
                  >
                    <mat-card style="max-width: 300px">
                      <mat-card-content>
                        Click on any item to Open
                      </mat-card-content>
                    </mat-card>
                  </mde-popover>

                  <div class="spacer"></div>
                  <mat-form-field>
                    <mat-select
                      #selectCompleted
                      [(value)]="selectedSorterComplete"
                      class="md-select"
                    >
                      <mat-option
                        *ngFor="let sorter of sortOptionsComplete"
                        [value]="sorter"
                        >{{ sorter }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <mat-table
                  *ngIf="myLearningsComplete.length > 0"
                  [dataSource]="dataSourceComplete"
                  class="md-table"
                >
                  <ng-container matColumnDef="name">
                    <mat-header-cell col-span="2" *matHeaderCellDef
                      ><span>Completed Items</span></mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let source"
                      [ngClass]="
                        source.archived ? 'learning-row failed' : 'learning-row'
                      "
                    >
                      <button
                        (click)="toggleFavorite(source)"
                        *ngIf="!isFavorite(source)"
                      >
                        <i class="fa fa-star-o"></i>
                      </button>
                      <button
                        (click)="toggleFavorite(source)"
                        *ngIf="isFavorite(source)"
                      >
                        <i class="fa fa-star"></i>
                      </button>
                      <div
                        fxLayout="row"
                        fxFlex="100"
                        fxLayoutAlign="start center"
                        (click)="openItem(source)"
                      >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'audio'"
                          >audiotrack</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'document'"
                          >picture_as_pdf</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'video'"
                          >ondemand_video</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'module'"
                          >tv</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'package'"
                          >snippet_folder</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'Quiz'"
                          >psychology</i
                        >
                        <i
                          class="material-icons"
                          [hidden]="source.type != 'externalLink'"
                          >link</i
                        >
                        <span class="resource-name">{{ source.name }}</span>
                        <div class="percent">
                          {{ source.completionDate | date }}
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row
                    *matHeaderRowDef="sortersComplete"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: sortersComplete"
                  ></mat-row>
                </mat-table>
                <mat-paginator
                  #completePaginator
                  fxFlex="50"
                  [pageSize]="10"
                  [length]="myLearningsComplete.length"
                >
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
