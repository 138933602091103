import { t } from '@delphire/fp'

type Type = t.TypeOf<typeof codec>

const name = 'ResourceType'

const codec = t.keyof(
  {
    document: null,
    image: null,
    audio: null,
    video: null,
    package: null
  },
  name
)

export { Type, name, codec }
