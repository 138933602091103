import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  SimpleChanges
} from '@angular/core'
import moment from 'moment'
import { environment } from '../../../environments/environment'
import { Task } from '../../interfaces/task'
import { UserInfo } from '../../interfaces/user-info'
import { LocalStorageService } from '../../services/local-storage-service.service'
import { RoadmapWeek } from '../interfaces/roadmap-week'

@Component({
  selector: 'roadmap-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.sass']
})
export class WeeklyRoadmap implements OnInit {
  @Input() roadmap: any
  @Input() taskLookup: any
  @Input() roadmapReady!: EventEmitter<boolean>
  @Input() objectives: any[] = []

  verbose: boolean = environment.production ? false : true

  currentUser: UserInfo = {}
  currentDate: any = moment()
  currentEmployeeDay: any = ''
  private _currentWeekNum: number = 0 // using setter to also call gotoWeek
  currentDay: any = ''
  roadmapEndDay: any = ''
  employeeStartDate: any

  currentWeek: RoadmapWeek = { title: '', subtitle: '' }
  filteredTasks: Task[] = []

  constructor(private localStorage: LocalStorageService) {}

  //~ ------------------------------------ INIT ------------------------------------

  ngOnInit(): void {
    if (this.verbose) {
      console.log('%c[ this.objectives ]', 'color: whitesmoke', this.objectives)
    }
    const { user } = this.localStorage.get('delphireUser')
    this.currentUser = user
    if (this.verbose) {
      console.log('%c[ user ]', 'color: lime', this.currentUser)
    }
    if (this.roadmapReady) {
      this.roadmapReady.subscribe((ready) => {
        this.init()
      })
    }
  }

  init(): void {
    if (this.verbose) {
      console.log('%c[ WEEKLY ROADMAP INIT ]', 'color: deeppink')
    }
    this.calculateDates()
  }

  //~ ------------------------------------ GETTERS / SETTERS / WATCHERS ------------------------------------

  ngOnChanges(changes: SimpleChanges) {
    if (changes['objectives']) {
      if (this.verbose) {
        console.log('%c[ changes ]', 'color: deeppink', changes)
      }
      if (this.verbose) {
        console.log(
          '%c[ changes[objectives] ]',
          'color: red',
          changes['objectives']
        )
      }
      this.objectives = changes['objectives'].currentValue
      this.init()
    }
  }

  set currentWeekNum(val: number) {
    this._currentWeekNum = val
    this.gotoWeek(val)
  }

  get currentWeekNum(): number {
    return this._currentWeekNum
  }

  //~ ------------------------------------ WEEK CHANGE  ------------------------------------

  gotoWeek(weekNum: number): void {
    this.filteredTasks = this.taskLookup.byWeek[this.currentWeekNum]
    if (this.filteredTasks) {
      this.filteredTasks.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
    }
    if (this.objectives) {
      this.currentWeek = this.objectives[weekNum]
        ? this.objectives[weekNum]
        : { title: '', subtitle: '' }
    }
  }

  //~ ------------------------------------ DATE CALCULATIONS ------------------------------------

  calculateDates(): void {
    switch (this.roadmap.startDateFormat) {
      case 'event':
        this.employeeStartDate = moment(this.roadmap.startDate)
        break
      default:
        this.employeeStartDate = moment(this.currentUser.employmentStartDate)
    }

    this.currentDate = moment()
    this.currentEmployeeDay = this.currentDate.diff(
      this.employeeStartDate,
      'days'
    )
    this.roadmapEndDay = moment(this.employeeStartDate).add(
      this.roadmap.weeks.length * 7,
      'days'
    )

    // if(this.verbose) { console.log('%c[ this.roadmap ]', 'color: red', this.roadmap) }
    // if(this.verbose) { console.log('%c[ this.employeeStartDate ]', 'color: red', this.employeeStartDate) }
    // if(this.verbose) { console.log('%c[ this.currentUser.employmentStartDate ]', 'color: red', this.currentUser.employmentStartDate) }
    // if(this.verbose) { console.log('%c[ this.currentEmployeeDay ]', 'color: red', this.currentEmployeeDay) }
    // if(this.verbose) { console.log('%c[ this.currentWeekNum ]', 'color: red', this.currentWeekNum) }
    // if(this.verbose) { console.log('%c[ this.currentDay ]', 'color: red', this.currentDay) }
    // if(this.verbose) { console.log('%c[ this.roadmapEndDay ]', 'color: red', this.roadmapEndDay) }

    if (
      this.currentDate <= this.employeeStartDate ||
      this.currentDate >= this.roadmapEndDay
    ) {
      this.currentWeekNum = 0
      this.currentDay = 0
    } else {
      this.currentWeekNum = this.currentDate.diff(
        this.employeeStartDate,
        'weeks'
      )
      this.currentDay =
        this.currentWeekNum >= 1
          ? this.currentEmployeeDay % 7
          : this.currentEmployeeDay
    }
    // if(this.verbose) { console.log('%c[ this.currentWeekNum ]', 'color: lime', this.currentWeekNum ) }
    // if(this.verbose) { console.log('%c[ this.currentDay ]', 'color: lime', this.currentDay ) }
  }
}
