import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import _ from 'lodash'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { take } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Course } from '../interfaces/course'
import { Task } from '../interfaces/task'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.sass']
})
export class CourseComponent implements OnInit {
  course: Course = {}
  courseId: string | undefined

  tasks: Task[] = []

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,

    private spinner: SpinnerVisibilityService
  ) {}

  getTasks(items: Task[], order: string[]): void {
    const { databaseUrl } = this.localStorage.get('currentSpace')
    order.forEach(async (o, index) => {
      const t: any = _.find(items, (i) => {
        return i.id === o
      })
      if (t.type === 'Resource') {
        const observable = this.fbS
          .db(databaseUrl)
          .object(
            environment.firebasePaths.space + 'resources/' + t.learningObjectId
          )
          .pipe(take(1))
        await observable.toPromise().then((resource) => {
          t.resource = resource
          this.tasks.splice(index, 0, t)
        })
      } else if (t.type === 'External Link') {
        t.link = {
          parameters: {
            url: t.learningObjectId
          }
        }
        this.tasks.splice(index, 0, t)
      } else {
        this.tasks.splice(index, 0, t)
      }
    })
    // console.log("this.tasks", this.tasks)
  }

  fetchCourseData(): void {
    const { databaseUrl } = this.localStorage.get('currentSpace')
    this.spinner.show()
    this.fbS
      .db(databaseUrl)
      .object(environment.firebasePaths.space + 'courses/' + this.courseId)
      .subscribe((course) => {
        this.course = course
        this.getTasks(course.objects, course.objectsOrder)
        this.spinner.hide()
      })
  }
  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.courseId = String(routeParams.get('courseId'))

    this.route.params.subscribe((params) => {
      if (params.libraryId !== this.courseId) {
        this.courseId = params.courseId
        this.course = {}
        this.tasks = []
        this.fetchCourseData()
      }
    })
  }
}
