<div class="agenda" ngClass.xs="mobile-xs">

  <header fxLayout="row" fxLayoutAlign="start center">
    <!-- ? missing a header bg image? add it to the brand sass -->
    <!-- ? frontend/app/src/brand/YOUR_BRAND/sass/_agenda.sass -->
    <!-- ? don't forget to import agenda.sass in overrides.sass -->
    <div class="title" fxLayout="row" fxLayoutAlign="start center">
      {{ agenda.name }}
    </div>
  </header>

  <nav fxLayout="row">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="center center" class="arrow" [ngClass]="{ 'disabled': viewingDay <= 0 }" (click)="previousDay()">
        <span class="material-icons"> arrow_back </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <p>{{ formatDay(viewingDate) }}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="arrow" [ngClass]="{ 'disabled': viewingDay >= (this.meetingDuration -1) }" (click)="nextDay()">
        <span class="material-icons"> arrow_forward </span>
      </div>
    </div>
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <div fxHide fxShow.gt-xs *ngIf="resourceExists" class="link-button" (click)="handleLink()"> {{ agendaConfig.viewPdf || 'View PDF' }} </div>
      <div fxHide fxShow.xs *ngIf="resourceExists" class="link-icon" (click)="handleLink()">
        <span class="material-icons"> description </span>
      </div>
    </div>
  </nav>

  <div class="events" fxLayout="column" (swiperight)="previousDay()" (swipeleft)="nextDay()">
    <div class="col-headers">
      <div fxFlex="25"> {{ agendaConfig.time || 'Time' }} </div>
      <div fxFlex> {{ agendaConfig.description || 'Description' }} </div>
      <div fxFlex="30"> {{ agendaConfig.location || 'Location' }} </div>
    </div>
    <div class="event" *ngFor="let event of filteredUserEvents" fxLayout="row">
      <div fxFlex="25">
        {{ getEventTime(event.startDate!) }} - {{ getEventTime(event.endDate!) }}
      </div>
      <div fxFlex fxLayout="column">
        <span class="name" *ngIf="event.name">{{ event.name }}</span>
        <span class="description" *ngIf="event.description">{{ event.description }}</span>
        <!-- TODO: Make event specific attachments work. CMS adds node for all, even if none selected -->
        <!-- TODO: Fix groups not populating dropdown in CMS -->
        <!-- <span class="link" *ngIf="event.links">{{ event.links }}</span> -->
      </div>
      <div fxFlex="30">
        {{ getLocationName(event.locationId!) }}
      </div>
    </div>
  </div>

</div>
