import { t } from '@delphire/fp'

export type Type = t.TypeOfProps<typeof props>

export type WithDescription = t.TypeOfProps<typeof propsWithDescription>

export const props = {
  name: t.string
}

export const propsWithDescription = {
  ...props,
  description: t.fromNullable(t.string, '')
}
