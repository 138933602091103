import { t } from '@delphire/fp'
import { TenantEntity } from '../data'
import * as SlideshowSlide from './SlideshowSlide'

export type Type = t.TypeOf<typeof codec>

export const name = 'Slideshow'

export const props = {
  ...TenantEntity.props,
  slides: SlideshowSlide.codecRecordFromNullable
}

export const codec = t.strict(props, name)
