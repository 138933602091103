<nav class="roadmap-nav roadmap-nav-level-1 roadmap-nav-tabs">
  <h3>Select the drop-down arrow to navigate sections of the roadmap</h3>
  <mat-select *ngIf="levels && levels.length > 0" [(value)]="currentPrimaryId" id="roadmap-select-custom-level-1"
    (selectionChange)="onPrimaryChange()">
    <mat-option class="roadmap-select-option" *ngFor="let level of levels" [value]="level.id">{{ level.name }}
    </mat-option>
  </mat-select>
</nav>

<div class="roadmap-objectives roadmap-objectives-level-1 roadmap-objectives-custom" *ngIf="
    currentLevel
    && currentLevel.description
    && currentLevel.description.title != ''
  ">
  <h3>{{ currentLevel.description.title }}</h3>

  <ng-container *ngIf="currentLevel.description.additional">
    <p *ngFor="let additional of currentLevel.description.additional">
      {{ additional }}
    </p>
  </ng-container>
</div>

<!-- ----------------------------------------------- NAV LEVEL 2 ----------------------------------------------- -->

<ng-container *ngIf="roadmap.levels.type == 'double' && currentLevel">

  <!-- ------------------------------ NAV LEVEL 2: CUSTOM (CHOOSE SUBLEVEL) ------------------------------ -->

  <mat-select id="roadmap-select-custom-level-2" [(value)]="currentSecondaryId" (selectionChange)="onSecondaryChange()">
    <mat-option class="roadmap-select-option" *ngFor="let level of sublevels" [value]="level.id">{{ level.name }}
    </mat-option>
  </mat-select>

  <!-- ------------------------------ OBJECTIVES LEVEL 2 (CUSTOM ONLY) ------------------------------ -->

  <div class="roadmap-objectives roadmap-objectives-level-2 roadmap-objectives-custom roadmap-border-bottom" *ngIf="
      currentSubLevel
      && currentSubLevel.description
      && currentSubLevel.description.title != ''
  ">
    <h3>{{ currentSubLevel.description.title }}</h3>
    <p *ngFor="let additional of currentSubLevel.description.additional">{{ additional }}</p>
  </div>

</ng-container>

<!-- ----------------------------------------------- TASKS ----------------------------------------------- -->

<div class="roadmap-content-box" *ngIf="filteredTasks && filteredTasks.length > 0">
  <div class="roadmap-tasks padded">
    <app-task *ngFor="let t of filteredTasks" [class]="'roadmap-item'" [task]="t"></app-task>
  </div>
</div>