import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scorm-completion',
  templateUrl: './scorm-completion.component.html',
  styleUrls: ['./scorm-completion.component.sass']
})
export class ScormCompletionComponent implements OnInit {

  backgroundUrl: string = '/brand/atp/assets/images/scorm-bg2.png'

  constructor() { }

  ngOnInit(): void {
  }

}
