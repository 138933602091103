import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { doc, getDoc, getFirestore } from '@angular/fire/firestore'
import { ActivatedRoute } from '@angular/router'
import _ from 'lodash'
import { first } from 'rxjs/operators'

import { environment } from '../../environments/environment'
import { ExternalLink } from '../interfaces/external-link'
import { Task } from '../interfaces/task'
import { ConfirmationDialogService } from '../services/confirmation-dialog.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { IconService } from '../services/icon.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { ProgressService } from '../services/progress.service'
import { UserPreferencesService } from '../services/user-preference.service'

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.sass']
})
export class TaskCardComponent implements OnInit {
  @Input()
  item: any

  @Input()
  progressType: string = 'In Progress'

  @Input()
  section: string | undefined

  @Output()
  newTaskEvent: EventEmitter<any> = new EventEmitter()

  objectKeys = Object.keys
  addFavorite() {
    this.userPreferences.setFavorites(this.item)
    console.log('%c[ this.item ]', 'color: aqua', this.item)
  }

  archivedResource: boolean = false

  constructor(
    public userPreferences: UserPreferencesService,
    private progressService: ProgressService,
    private iconService: IconService,
    private linkService: DelphireLinkService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private fbs: FirebaseService,
    private dialogService: ConfirmationDialogService
  ) {}

  getTypeIcon(type: string, solid?: boolean): string {
    if (!solid) solid = false
    return this.iconService.getIcon(type.toLowerCase(), solid)
  }

  selectItem(task: Task): void {
    if (this.archivedResource) {
      const dialogOptions = {
        title: 'Archived or Inaccessible Space',
        message:
          'The item you are trying to access is either in an archived space or you no longer have access to the space. If you believe this is incorrect please contact an administrator.',
        cancelText: 'CANCEL',
        confirmText: 'OK',
        panelClass: 'archivedDialog'
      }
      this.dialogService.open(dialogOptions)

      this.dialogService.confirmed().subscribe((confirmed) => {})
      return
    }
    this.newTaskEvent.emit({ task: task })
    if (task.type) {
      switch (task.type) {
        case 'audio':
        case 'video':
          if (
            task.resource?.type === 'audio' ||
            task.resource?.type === 'video'
          ) {
            const {
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            } = task
            task.resource = {
              ...task.resource,
              completed,
              started,
              progress,
              mediaCompleted,
              mediaLastTime,
              mediaPercentage
            }
          }
          this.linkService.handleLink({
            type: task.resource?.type,
            resource: task.resource,
            route: this.route
          })
          break
        case 'document':
        case 'package':
          this.linkService.handleLink({
            type: task.resource?.type,
            resource: task.resource,
            route: this.route
          })
          break
        case 'external link':
          const { databaseUrl } = this.localStorage.get('currentSpace')
          this.fbs
            .db(databaseUrl)
            .object(
              environment.firebasePaths.space + 'externalLinks/' + task.id
            )
            .subscribe((extLink) => {
              if (extLink) {
                const { url } = extLink as ExternalLink
                window.open(url, '_blank')
              } else {
                window.open(task.url, '_blank')
              }
            })
          break

        default:
          const type =
            task.resource?.type?.toLowerCase() || task.type?.toLowerCase()
          this.linkService.handleLink({
            type: type,
            id: task.id || this.item[type + 'Id'],
            route: this.route
          })
          break
      }
    }
  }

  async getResource(item: Task) {
    let node = 'resources'
    let id = item.resourceId
    switch (item.type?.toLowerCase()) {
      case 'agenda':
        node = 'agenda'
        id = item.agendaId
        break
      case 'course':
        node = 'courses'
        id = item.courseId
        break
      case 'external link':
        node = 'externalLinks'
        id = item.linkId
        break
      case 'library':
        node = 'libraries'
        id = item.libraryId
        break
      case 'roadmap':
        node = 'roadmaps'
        id = item.roadmapId
        break
      case 'quiz':
        node = 'quizzes'
        id = item.quizId
        break
      case 'verbalizer':
        node = 'verbalizers'
        id = item.verbalizerId
        break
      default:
        node = 'resources'
        id = item.resourceId
        break
    }

    const docRef = doc(getFirestore(), node + '/' + id)
    return await getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        const resource = doc.data()
        item.resource = resource
        return item
      } else {
        return item
      }
    })
  }
  async addAncillaryData() {
    if (
      this.item.resource &&
      this.item.type !== 'roadmap' &&
      this.item.type !== 'library'
    ) {
      this.progressService
        .getFirestoreProgressData(this.item)
        .subscribe((task) => {
          this.item = task
        })
    } else {
      const enhancedItem = await this.getResource(this.item)
      this.item = enhancedItem
    }
  }
  ngOnInit(): void {
    // Initial Favorites assignment
    const fav = _.find(this.userPreferences.favorites, {
      id: this.item.id || this.item[this.item.type + 'Id']
    })
    if (fav) {
      this.item.isFavorite = true
    } else {
      this.item.isFavorite = false
    }
    //Now Watch for new Favorites
    this.userPreferences.favorites$.subscribe((favorites) => {
      favorites.subscribe((favoritesArr) => {
        const fav = _.find(favoritesArr, {
          id: this.item.id || this.item[this.item.type + 'Id']
        })
        if (fav) {
          this.item.isFavorite = true
        } else {
          this.item.isFavorite = false
        }
      })
    })

    if (this.item.downloadUrl) {
      this.item.downloadUrl.pipe(first()).subscribe((val: any) => {
        if (val.body.error) {
          this.archivedResource = true
          this.item.archived = true
        }
      })
    }

    this.addAncillaryData()
  }
}
