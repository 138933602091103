import { t } from '@delphire/fp'
import { IAMEntity } from '../data'

export type Type = t.TypeOf<typeof codec>

export const name = 'Organization'

export const props = {
  ...IAMEntity.props,
  defaultGroupId: t.nullable(t.string)
}

export const codec = t.strict(props, name)
