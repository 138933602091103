import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'WidgetType'

export const codec = t.keyof(
  {
    ['Agenda']: null,
    ['Countdown']: null,
    ['Course']: null,
    ['Checklist']: null,
    ['da-recommendations']: null,
    ['External Link']: null,
    ['Fluency Tool']: null,
    ['Library']: null,
    ['Modal']: null,
    ['Quiz']: null,
    ['Resource']: null,
    ['Roadmap']: null,
    ['Slideshow']: null,
    ['Task List']: null,
    ['Verbalizer']: null
  },
  name
)
