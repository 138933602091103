import { t } from '@delphire/fp'

export type Type = t.TypeOf<typeof codec>

export const name = 'LayoutLayout'

export const props = {
  i: t.string,
  minH: t.number,
  minW: t.number,
  h: t.number,
  w: t.number,
  x: t.number,
  y: t.number,
  moved: t.fromNullable(t.boolean, false),
  static: t.fromNullable(t.boolean, false)
}

export const codec = t.strict(props, name)

export const codecArray = t.array(codec)

export const codecArrayFromNullable = t.fromNullable(codecArray, [])
