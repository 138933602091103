<div class="modal-header" *ngIf="resource">
  <button type="button" class="close" (click)="close()">
    <i class="fa fa-times"></i>
  </button>
  <button
    mat-no-ink="true"
    style="
      min-height: 0px !important;
      min-width: 0px !important;
      line-height: 0px !important;
    "
    title="click to copy document link"
    class="dynamicLink"
    aria-label="Link"
    (click)="generateLink()"
  >
    <span class="material-icons">link</span>
  </button>
  <h4 class="modal-title">{{ resource.name }}</h4>
</div>
<div class="modal-body">
  <div class="embed-responsive embed-responsive-4by3">
    <div class="embed-responsive-item">
      <div id="pspdfkits" [ngStyle]="{ height: '480px', width: '100vw' }"></div>
    </div>
  </div>
  <div class="description" *ngIf="resource.description">
    <i
      class="fa fa-fw fa-chevron-circle-right"
      [ngClass]="{ 'fa-rotate-90': !isCollapsed }"
    ></i>
    <h5 class="description-header" ng-click="isCollapsed = !isCollapsed">
      Description/Transcript
    </h5>
    <div class="video-description-hold" uib-collapse="isCollapsed">
      <div class="video-description" [innerHtml]="resource.description"></div>
    </div>
  </div>
</div>
