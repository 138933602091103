import { Injectable } from '@angular/core'
import { Http, HttpDownloadFileResult } from '@capacitor-community/http'
import { Directory } from '@capacitor/filesystem'
import { Storage } from '@capacitor/storage'
import { File } from '@ionic-native/file/ngx'

import { Resource } from '../interfaces/resource'
import { DelphireApiService } from './delphire-api.service'

export type EXTTYPE = 'pdf' | 'text' | 'doc' | 'html'

@Injectable({
  providedIn: 'root'
})
export class DelphireFileService {
  async checkFile(resource: Resource) {
    try {
      const ext = resource?.sources?.original.ext
      const { value } = await Storage.get({ key: resource.id + ':' + ext })

      if (value) {
        if (value !== resource.updatedAt) {
          await this.file.removeFile(
            this.file.documentsDirectory,
            ext + 's/' + resource.id + '.' + ext
          )
          await Storage.remove({ key: resource.id + ':' + ext })
          return 'FILE UPDATED'
        }
      }

      await this.file.checkFile(
        this.file.documentsDirectory,
        ext + 's/' + resource.id + '.' + ext
      )
      return this.file.documentsDirectory + ext + 's/' + resource.id + '.' + ext
    } catch (error) {
      return 'FILE NOT FOUND'
    }
  }

  async downloadFileToPath(url: string, resource: Resource, path?: string) {
    const ext = resource?.sources?.original.ext
    const options = {
      url: url,
      filePath: path ? path : ext + 's/' + resource.id + '.' + ext,
      fileDirectory: Directory.Documents
    }

    try {
      const doc: HttpDownloadFileResult = await Http.downloadFile(options)

      return doc.path || 'UNABLE TO SAVE FILE'
    } catch (error) {
      return 'UNABLE TO SAVE FILE'
    }
  }

  async getFile(resource: Resource) {
    const ext = resource?.sources?.original.ext
    const fromCache = await this.checkFile(resource)
    return new Promise<string>((resolve, reject) => {
      if (fromCache !== 'FILE NOT FOUND' && fromCache !== 'FILE UPDATED') {
        resolve(fromCache)
      } else {
        if (resource.id && resource.updatedAt)
          Storage.set({
            key: resource.id + ':' + ext,
            value: resource.updatedAt
          })
        this.delphireAPI
          .postRequest('resources/getDownloadUrl', {
            id: resource.id,
            sourceKey: 'original'
          })
          .then((response) => {
            response.toPromise().then(async (response) => {
              const { body } = response
              const { result } = body
              const { url } = result
              const file = await this.downloadFileToPath(url, resource)
              resolve(file)
            })
          })
      }
    })
  }
  constructor(private file: File, private delphireAPI: DelphireApiService) {}
}
