import { t } from '@delphire/fp'
import * as QuizAnswerObject from './QuizAnswerObject'

export type Type = t.TypeOf<typeof codec>

export const name = 'QuizAnswer'

export const codec = t.union([t.string, QuizAnswerObject.codec], name)

export const codecArray = t.array(codec)

export const codecArrayFromNullable = t.fromNullable(codecArray, [])
