import { t } from '@delphire/fp'
import { TenantEntity } from '../data'
import * as QuizQuestion from './QuizQuestion'

export type Type = t.TypeOf<typeof codec>

export const name = 'Quiz'

export const codecNumberLike = t.union([t.number, t.NumberFromString])

export const props = {
  ...TenantEntity.props,
  type: t.nullable(t.string),
  metadata: t.unknown,
  passMessage: t.nullable(t.string),
  failMessage: t.nullable(t.string),
  passPercentage: t.nullable(codecNumberLike),
  questionCount: t.nullable(codecNumberLike),
  showCorrectAnswers: t.nullable(t.boolean),
  questions: QuizQuestion.codecRecordFromNullable
}

export const codec = t.strict(props, name)
