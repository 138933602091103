import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'quiz-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.sass']
})
export class LandingComponent implements OnInit {
  @Input() quiz: any

  @Output('closeQuiz') closeQuiz: EventEmitter<any> = new EventEmitter()
  @Output('startQuiz') startQuiz: EventEmitter<any> = new EventEmitter()

  constructor() {}

  quit(): void {
    this.closeQuiz.emit()
  }

  start(): void {
    this.startQuiz.emit()
  }

  ngOnInit(): void {}
}
